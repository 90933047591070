(function () {
  'use strict';
  angular
    .module('excersise.truncatedText', [
      'excersise.truncatedText.splash',
      'excersise.truncatedText.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/truncatedText', '/excersise/truncatedText/splash');
      $stateProvider
        .state('excersise.truncatedText', {
          abstract: false,
          url: '/truncatedText',
          template: '<ui-view/>'
        });
    });
}());
