(function () {
  'use strict';
  angular
    .module('excersise.diamondColumn', [
      'excersise.diamondColumn.splash',
      'excersise.diamondColumn.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/diamondColumn', '/excersise/diamondColumn/splash');
      $stateProvider
        .state('excersise.diamondColumn', {
          abstract: false,
          url: '/diamondColumn',
          template: '<ui-view/>'
        });
    });
}());
