(function () {
  'use strict';
  angular
    .module('excersise.wordDiamond', [
      'excersise.wordDiamond.splash',
      'excersise.wordDiamond.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/wordDiamond', '/excersise/wordDiamond/splash');
      $stateProvider
        .state('excersise.wordDiamond', {
          abstract: false,
          url: '/wordDiamond',
          template: '<ui-view/>'
        });
    });
}());
