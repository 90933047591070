(function () {
  'use strict';
  class Controller {
    constructor($window, $interval, $timeout) {
      this._ = $window._;
      this.$interval = $interval;
      this.$timeout = $timeout;
    }
    $processWords(words = {}) {
      const _ = this._;
      let wordAmount = parseInt(this.wordAmount || 14, 10),
          selectedWords = _(words).sample(wordAmount),
          removedWords = _(selectedWords).sample(wordAmount / 2),
          testWords = _(selectedWords).shuffle();
      this.$start(selectedWords, removedWords, testWords);
    }
    $start(selectedWords, removedWords, testWords) {
      let interval = parseInt(this.interval || 750, 10),
          repetitions = removedWords.length;
      this.words = selectedWords;
      this.removedWords = removedWords;
      this.blockedWords = {};
      this.$progress = 1;
      this
        .$interval((iteration) => {
          let currentWord = removedWords[iteration - 1];
          this.currentIteration = iteration;
          this.blockedWords[currentWord] = true;
        }, interval, repetitions + 1)
        .then(() => {
          this.testWords = testWords;
        });
    }
    $choose(chosen, word, length) {
      const _ = this._;
      if (
        _(chosen).chain().compact().size().value() < length / 2 &&
        !chosen[word]
      ) {
        chosen[word] = word;
      } else {
        chosen[word] = false;
      }
    }
    $verify(selectedWords) {
      const _ = this._;
      let words = (this.wordAmount || 14) / 2,
          correct,
          incorrect,
          missing;
      selectedWords = _(selectedWords).chain().toArray().compact().value();
      correct = _.intersection(selectedWords, this.removedWords);
      incorrect = _.difference(selectedWords, this.removedWords);
      missing = _.difference(this.removedWords, selectedWords);
      this.then({
        $data: correct.length / words,
        $correct: correct,
        $incorrect: incorrect,
        $missing: missing
      });
    }
  }
  angular
    .module('excersise.absentWords.excersise.component', [])
    .component('absentWordsExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/absentWords/excersise/component/component-excersise-absentWords.comp.html',
      bindings: {
        interval: '<',
        wordAmount: '<',
        then: '&'
      }
    });
}());
