(function () {
  'use strict';
  class Controller {
    constructor($timeout, $window, $mdMedia) {
      const AVERAGE_LETTER_WIDTH = 10;
      let canvasWidth,
          columns = $mdMedia('xs') ? 2 : 4;
      this.$timeout = $timeout;
      this._ = $window._;
      canvasWidth = $window.innerWidth < 600 - 32 ? $window.innerWidth - 32 : 600 - 32;
      this.horizontalLetters = canvasWidth * 2 / (columns * AVERAGE_LETTER_WIDTH);
    }
    $onInit() {
      const _ = this._;
      let columnWidth = this.horizontalLetters,
          currentColumn = {
            length: columnWidth,
            number: 0
          },
          columns;
      this.words = _(this.phrases)
        .chain()
        .map(phrase => phrase.content.split(' '))
        .each(phraseWords => {
          phraseWords[phraseWords.length - 1] += '.';
        })
        .flatten()
        .value();
      columns = _(this.words)
        .groupBy(word => {
          if (currentColumn.length < word.length) {
            currentColumn.length = columnWidth;
            currentColumn.number += 1;
          }
          currentColumn.length -= word.length;
          return currentColumn.number;
        });
      this.columns = columns;
      this.$setTimeout();
    }
    $setTimeout() {
      const MIN_WPM = 2000;
      let totalWords = this.words.length,
          minMinutes = totalWords / MIN_WPM,
          minTime = minMinutes * 60000;
      this.timeout = this.$timeout(() => {
        this.$canContinue = true;
      }, minTime);
      this.init = Date.now();
    }
    $then() {
      let totalWords = this.words.length,
          totalMilliseconds = Date.now() - this.init,
          totalMinutes = totalMilliseconds / 60000,
          wpm = totalWords / totalMinutes;
      this.then({
        $data: wpm
      });
    }
  }
  angular
    .module('excersise.zigzagText.excersise.component', [])
    .component('zigzagTextExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/zigzagText/excersise/component/component-excersise-zigzagText.comp.html',
      bindings: {
        title: '<',
        phrases: '<',
        then: '&'
      }
    });
}());
