(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
      this.cycle = 1;
      this.cycleTimings = {};
      this.cycleDurations = [];
    }
    $onInit() {
      this.wordAmount = parseInt(this.wordAmount || 14, 10);
      this.cycleAmount = parseInt(this.cycleAmount || 10, 10);
    }
    $processWords(words = {}) {
      this.words = words;
      this.$reset();
    }
    $reset() {
      const _ = this._;
      let wordAmount = this.wordAmount,
          dictionary = _(this.words).toArray(),
          selectedWord = _(this.words).sample(),
          wordIndex = dictionary.indexOf(selectedWord),
          startIndex = wordIndex - wordAmount / 2,
          endIndex = wordIndex + wordAmount / 2,
          testWords = _(dictionary.slice(startIndex, endIndex)).shuffle();
      this.cycleTimings[this.cycle] = Date.now();
      this.testWords = testWords;
      this.selectedWord = selectedWord;
    }
    $verifyWord(word) {
      if (word === this.selectedWord) {
        this.$tick();
      }
    }
    $tick() {
      const _ = this._;
      let cycleStart = this.cycleTimings[this.cycle],
          cycleDurations = this.cycleDurations,
          cycleAmount = this.cycleAmount;
      cycleDurations.push(Date.now() - cycleStart);
      if (this.cycle < cycleAmount) {
        this.cycle += 1;
        this.$reset();
      } else {
        let averageTiming = _(cycleDurations)
          .reduce((memo, num) => {
            return memo + num;
          }) / cycleDurations.length || 1;
        this.then({
          $data: averageTiming
        });
      }
    }
  }
  angular
    .module('excersise.similarWords.excersise.component', [])
    .component('similarWordsExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/similarWords/excersise/component/component-excersise-similarWords.comp.html',
      bindings: {
        wordAmount: '<',
        cycleAmount: '<',
        then: '&'
      }
    });
}());
