(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('home.levels', [
      'home.levels.progress',
      'home.levels.level1',
      'home.levels.level2',
      'home.levels.level3',
      'home.levels.level4',
      'home.levels.level5'
    ])
    .component('homeLevels', {
      controller: Controller,
      templateUrl: 'root/home/_components/levels/levels-home.comp.html'
    });
}());
