(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
      this.chartOptions = {
        scales: {
          yAxes: [{
            ticks: {
              callback(value) {
                return `${value}%`;
              }
            }
          }],
          xAxes: [{
            type: 'time',
            ticks: {
              callback() {
                return '';
              }
            }
          }]
        },
        elements: {
          line: {
            tension: 0
          }
        }
      };
    }
    parseData(data) {
      const _ = this._;
      return _(data)
        .map((item) => {
          return {
            x: parseInt(item.key, 10),
            y: item.value * 100
          };
        });
    }
  }
  angular
    .module('excersise.word-pyramid.splash', [])
    .component('excersiseWordPyramidSplash', {
      controller: Controller,
      templateUrl: 'root/excersise/word-pyramid/splash/splash-word-pyramid-excersise.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('excersise.word-pyramid.splash', {
          abstract: false,
          url: '/splash',
          template: '<excersise-word-pyramid-splash/>'
        });
    });
}());
