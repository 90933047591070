(function () {
  'use strict';
  angular
    .module('excersise.numbersWarmup', [
      'excersise.numbersWarmup.excersise.component'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/numbersWarmup', '/excersise/numbersWarmup/splash');
      $stateProvider
        .state('excersise.numbersWarmup', {
          abstract: false,
          url: '/numbersWarmup',
          template: '<ui-view/>'
        });
    });
}());
