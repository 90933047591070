
(function () {
  'use strict';
  class Controller {
    constructor($window, $firedux, $http, $state) {
      this.$state = $state;
      this._ = $window._;
      this.moment = $window.moment;
      this.$firedux = $firedux;
      this.$http = $http;
      this.isExpanded = true;
      this.showGlobalStats = true;
      this.percentageOptions = Object.assign({}, this.chartOptions(), {
        scales: {
          yAxes: [{
            beginAtZero: true,
            ticks: {
              callback(value) {
                return `${Math.floor(value * 100)}%`;
              }
            }
          }],
          xAxes: [{
            type: 'time',
            ticks: {
              callback() {
                return '';
              }
            }
          }]
        }
      });
      this.chartOptionsObj = {
        milliseconds: this.chartOptions('milliseconds'),
        percentage: this.chartOptions('percentage'),
        wpm: this.chartOptions(),
        main: this.chartOptions()
      };
    }
    $redirect(userId) {
      this.$state.go(
        'group',
        {institutionId: this.$params.institutionId, groupId: this.$params.groupId, userId},
        {location: 'replace'}
      );
    }
    $getPersonalData(studentId, {institutionId, groupId}) {
      const {_} = this;
      return Promise.all([
        this.$http.get('https://us-central1-intelligere-94e9b.cloudfunctions.net/globalStats?studentId=' + studentId)
          .then(({data}) => {
            this.$personalStats = data;
          }),
        this.$http.get('https://us-central1-intelligere-94e9b.cloudfunctions.net/globalStats?institutionId=' + institutionId + '&groupId=' + groupId)
          .then(({data}) => {
            this.$globalStats = data;
            this.$sortedUserIds = _.map(_.sortBy(_.map(data.sortedUsers, (user, userId) => ({
              userId,
              mean: user.wpm.mean * user.comprehension.mean * user.percentage.mean
            })), item => item.mean), 'userId');
          })
      ]).then(() => {
        const personalCategories = _.object(_.map(this.$personalStats.category, (item, category) => [category, item.mean])),
            globalCategories = _.object(_.map(this.$globalStats.category, (item, category) => [category, item.mean])),
            toBeImproved = _.keys(_.object(_.filter(_.pairs({
              retención: personalCategories.percentage < globalCategories.percentage,
              agilidad: personalCategories.milliseconds > globalCategories.milliseconds,
              velocidad: personalCategories.wpm < globalCategories.wpm,
              comprensión: personalCategories.comprehension < globalCategories.comprehension,
              'agilidad numérica': personalCategories.number < globalCategories.number
            }), ([, value]) => value)));
        this.$improvementAreas = toBeImproved;
        this.$radar = this.$getRadarData();
        this.$individualData = this.$getIndividualData();
        this.$labelsData = this.$getLabelsData();
        this.$firedux.$apply();
      });
    }
    $getSortedUserNames() {
      return this._.map(this.$sortedUserIds, userId => ((this.groupMembers || {})[userId] || {}).displayName);
    }
    $getBestUsers() {
      return this._.first(this.$getSortedUserNames(), 2);
    }
    $getWorstUsers() {
      return this._.last(this.$getSortedUserNames(), 2);
    }
    $size(data) {
      return this._.toArray(data).length;
    }
    $getImprovement(lessons) {
      return this.$personalStats && this.$labelsData ? this._(lessons).chain()
        .keys()
        .map(lessonId => [
          (this.$labelsData[lessonId] || []).length,
          (this.$personalStats.lessons[lessonId] || {}).effectiveness < 40,
          [this.excersises[lessonId].displayName, (this.$personalStats.lessons[lessonId] || {}).color, lessonId]
        ])
        .filter(([data, effectiveness]) => data && effectiveness)
        .map(2)
        .value() : [];
    }
    getProgress(sessionProgress) {
      return this._(sessionProgress).reduce((memo, item) => memo + item, 0);
    }
    $parseSessions(sessions) {
      return this._(sessions).chain().toArray().filter(session => this.getProgress(session.progress)).value().reverse();
    }
    $getLabels(array) {
      return this._.map(array, (item, i) => `#${i + 1}`);
    }
    $getLabelsData() {
      return this._.mapObject(
        this.$personalStats.lessons,
        ({list}) => this.$getLabels(list)
      );
    }
    $getIndividualData() {
      return this._.mapObject(
        this.$personalStats.lessons,
        ({list}, lessonId) => [
          this._.map(list, (item, i) => ({x: i, y: item})),
          this._.map(list, (item, i) => ({x: i, y: (this.$globalStats.lessons[lessonId] || {}).topAvg}))
        ]
      );
    }
    $getRadarData() {
      return {
        options: {
          legend: {
            display: true
          }
        },
        datasetOverride: [
          {
            label: 'Promedio estudiante',
            backgroundColor: 'rgba(33,150,243,0.15)',
            borderColor: 'rgba(33,150,243,1)',
            pointBackgroundColor: 'rgba(33,150,243,0.15)',
            pointBorderColor: 'rgba(33,150,243,1)'
          },
          {
            label: 'Máximo estudiante',
            backgroundColor: 'rgba(3,169,244,0.05)',
            borderColor: 'rgba(3,169,244,1)',
            pointBackgroundColor: 'rgba(3,169,244,0.05)',
            pointBorderColor: 'rgba(3,169,244,1)',
            borderDash: [4, 4]
          },
          // {
          //   label: 'Promedio global',
          //   backgroundColor: 'rgba(76,175,80,0.05)',
          //   borderColor: 'rgba(76,175,80,1)',
          //   pointBackgroundColor: 'rgba(76,175,80,0.05)',
          //   pointBorderColor: 'rgba(76,175,80,1)'
          // },
          {
            label: 'Promedio grupo',
            backgroundColor: 'rgba(103,58,183,0.05)',
            borderColor: 'rgba(103,58,183,0.4)',
            pointBackgroundColor: 'rgba(0,0,0,0.05)',
            pointBorderColor: 'rgba(0,0,0,0.4)'
          },
          {
            label: 'Mejor grupo',
            backgroundColor: 'rgba(103,58,183,0.05)',
            borderColor: 'rgba(103,58,183,0.25)',
            pointBackgroundColor: 'rgba(0,0,0,0.05)',
            pointBorderColor: 'rgba(0,0,0,0.25)',
            borderDash: [4, 4]
          }
        ],
        labels: [
          'Agilidad',
          'Velocidad',
          'Comprensión',
          'Número',
          'Retención'
        ],
        data: [
          [
            getPercentage(this.$personalStats.category.milliseconds.mean, this.$globalStats.category.milliseconds.max, this.$globalStats.category.milliseconds.min),
            getPercentage(this.$personalStats.category.wpm.mean, 0, this.$globalStats.category.wpm.max),
            this.$personalStats.category.comprehension.mean * 100,
            getPercentage(this.$personalStats.category.number.mean, 0, this.$globalStats.category.number.max),
            this.$personalStats.category.percentage.mean * 100
          ],
          [
            getPercentage(this.$personalStats.category.milliseconds.lowAvg, this.$globalStats.category.milliseconds.max, this.$globalStats.category.milliseconds.min),
            getPercentage(this.$personalStats.category.wpm.topAvg, 0, this.$globalStats.category.wpm.max),
            min(this.$personalStats.category.comprehension.topAvg * 100, 100),
            getPercentage(this.$personalStats.category.number.topAvg, 0, this.$globalStats.category.number.max),
            min(this.$personalStats.category.percentage.topAvg * 100, 100)
          ],
          [
            getPercentage(this.$globalStats.category.milliseconds.mean, this.$globalStats.category.milliseconds.max, this.$globalStats.category.milliseconds.min),
            getPercentage(this.$globalStats.category.wpm.mean, 0, this.$globalStats.category.wpm.max),
            min(this.$globalStats.category.comprehension.mean * 100, 100),
            getPercentage(this.$globalStats.category.number.mean, 0, this.$globalStats.category.number.max),
            min(this.$globalStats.category.percentage.mean * 100, 100)
          ],
          [
            getPercentage(this.$globalStats.category.milliseconds.lowAvg, this.$globalStats.category.milliseconds.max, this.$globalStats.category.milliseconds.min),
            getPercentage(this.$globalStats.category.wpm.topAvg, 0, this.$globalStats.category.wpm.max),
            min(this.$globalStats.category.comprehension.topAvg * 100, 100),
            getPercentage(this.$globalStats.category.number.topAvg, 0, this.$globalStats.category.number.max),
            min(this.$globalStats.category.percentage.topAvg * 100, 100)
          ]
        ]
      };
      function getPercentage(i, minNum, max) {
        return (i - minNum) * 100 / (max - minNum);
      }
      function min(a, b) {
        return a > b ? b : a;
      }
    }
    $getAvgData(scores) {
      const {_} = this,
          {millisecondsList = [], wpmList = [], numberList = [], percentageList = []} = _.groupBy(
            scores,
            ({percentage, milliseconds, wpm, number}) => percentage && 'percentageList' ||
              milliseconds && 'millisecondsList' ||
              wpm && 'wpmList' ||
              number && 'numberList'
          );
      return {
        milliseconds: ((_(millisecondsList).chain().map('milliseconds').max().value() - _(millisecondsList).chain().map('milliseconds').reduce((m, i) => m + i, 0).value() / millisecondsList.length) / 1000).toFixed(2),
        wpm: (_(wpmList).chain().map('wpm').reduce((m, i) => m + i, 0).value() / wpmList.length).toFixed(0),
        comprehension: (_(wpmList).chain().map('comprehension').reduce((m, i) => m + i, 0).value() * 100 / wpmList.length).toFixed(0),
        number: (_(numberList).chain().map('number').reduce((m, i) => m + i, 0).value() / numberList.length).toFixed(0),
        percentage: (_(percentageList).chain().map('percentage').reduce((m, i) => m + i, 0).value() * 100 / percentageList.length).toFixed(1)
      };
    }
    chartOptions(unit) {
      const self = this;
      return {
        scales: {
          yAxes: [{
            beginAtZero: true,
            ticks: {
              callback(value) {
                return `${((value || 0) * self.unitFactor(unit)).toFixed(0)}${getUnit()}`;
              }
            }
          }]
        },
        elements: {
          line: {
            tension: 0
          }
        }
      };
      function getUnit() {
        if (unit === 'percentage') {
          return '%';
        }
        if (unit === 'milliseconds') {
          return 's';
        }
        return '';
      }
    }
    unitFactor(unit) {
      let returnable;
      switch (unit) {
        case 'milliseconds':
          returnable = 1 / 1000;
          break;
        case 'percentage':
          returnable = 100;
          break;
        default:
          returnable = 1;
          break;
      }
      return returnable;
    }
    $parseProgress(progress, variable) {
      const _ = this._;
      return _(progress)
        .chain()
        .filter((plop, key) => key.indexOf(variable) > -1)
        .map(results => _(results)
          .map((value, timestamp) => ({
            y: value || 0,
            x: parseInt(timestamp, 10)
          })))
        .flatten()
        .sortBy('x')
        .map(({x, y}, index) => {
          return {
            x: index,
            y
          };
        })
        .value();
    }
    $parseAll(progress) {
      const _ = this._;
      return _(progress)
        .mapObject(results => _(results)
          .chain()
          .map((value, timestamp) => ({
            y: value || 0,
            x: parseInt(timestamp, 10)
          }))
          .sortBy('x')
          .map(({x, y}, index = 0) => {
            return {
              x: index,
              y
            };
          })
          .value());
    }
    $merge(chart1, chart2) {
      const _ = this._;
      return _(chart1)
        .chain()
        .map((item1, index) => [item1, chart2[index]])
        .filter(([item1, item2]) => item1 && item2)
        .map(({x, y1}, {y2}) => ({
          x,
          y: y2 * y1
        }))
        .value();
    }
    $getGroupMembers(members) {
      const {_, $firedux} = this;
      let userIds = _(members).keys();
      Promise.all(_(userIds).map(userId => $firedux.ref('students').child(userId).child('progress/global').once('value').then(snap => snap.val())))
        .then(students => _(students).map(progress => {
          let wpmData = this.$parseProgress(progress, 'Wpm'),
              comprehensionData = this.$parseProgress(progress, 'Comprehension', true),
              consolidatedData = this.$merge(comprehensionData, wpmData),
              individualData = this.$parseAll(progress);
          return {consolidatedData: _(consolidatedData).sortBy('x'), individualData: _(individualData).mapObject(excersiseData => _(excersiseData).sortBy('x'))};
        }))
        .then((users) => _(users).reduce((memo, {consolidatedData, individualData}) => {
          memo.consolidatedData.push(consolidatedData);
          _(individualData).each((data, excersiseId) => {
            if (!memo.individualData[excersiseId]) {
              memo.individualData[excersiseId] = [];
            }
            memo.individualData[excersiseId].push(data);
          });
          return memo;
        }, {
          consolidatedData: [],
          individualData: {}
        }))
        .then(({consolidatedData, individualData}) => ({
          consolidatedData: _(consolidatedData).reduce((memo, dataItem) => {
            _(dataItem).each(({x, y}, index) => {
              if (!memo[index]) {
                memo[index] = {
                  x: 0,
                  y: 0
                };
              }
              memo[index].x += _(x).isNumber() && !_(x).isNaN() ? x : 0;
              memo[index].y += _(y).isNumber() && !_(y).isNaN() ? y : 0;
            });
            return memo;
          }, []),
          individualData: _(individualData).mapObject(excersise => _(excersise).reduce((memo, dataItem) => {
            _(dataItem).each(({x, y}, index) => {
              if (!memo[index]) {
                memo[index] = {
                  x: index,
                  y: 0
                };
              }
              memo[index].y += _(y).isNumber() && !_(y).isNaN() ? y : 0;
            });
            return memo;
          }, []))
        }))
        .then(results => {
          return results;
        })
        .then(({consolidatedData, individualData}) => {
          this.$consolidatedGroupData = consolidatedData;
          this.$individualGroupData = individualData;
          $firedux.$apply();
        });
    }
  }
  angular
    .module('group.progress', [])
    .component('groupProgress', {
      controller: Controller,
      templateUrl: 'root/group/_components/progress/progress-group.comp.html'
    });
}());
