(function () {
  'use strict';
  class Service {
    constructor($rootScope, $window, $firedux) {
      this._ = $window._;
      this.$rootScope = $rootScope;
      this.$firedux = $firedux;
      this.$window = $window;
    }
    logLession({studentId, levelId, lessonId, duration, wpm, comprehension, percentage, milliseconds, points, number, sublevel}) {
      this.$firedux.ref(`students/${studentId}/scores`).push({
        timestamp: {
          '.sv': 'timestamp'
        },
        duration,
        lessonId,
        sublevel: sublevel || null,
        percentage: percentage || null,
        wpm: wpm || null,
        comprehension: comprehension || null,
        milliseconds: milliseconds || null,
        number: number || null,
        points: points > 0 ? points : 0
      });
      this.$firedux.ref(`students/${studentId}/progress/${levelId}/${lessonId}`)
        .transaction(currentData => (currentData || 0) + 20);
    }
    createSession({studentId, levelId}) {
      this.$firedux.ref(`students/${studentId}/sessions`).push({
        createdAt: {
          '.sv': 'timestamp'
        },
        levelId
      });
    }
    logSessionProgress({studentId, sessionId, step, score}) {
      this.$firedux.ref(`students/${studentId}/sessions/${sessionId}/progress/${step}`).set(score);
    }
    finishSession({studentId, sessionId}) {
      this.$firedux.ref(`students/${studentId}/sessions/${sessionId}`).update({
        finishedAt: {
          '.sv': 'timestamp'
        }
      });
    }
  }
  angular
      .module('app.logic', [])
      .service('$intelligere', Service);
}());
