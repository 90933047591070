(function () {
  'use strict';
  class Controller {
    constructor($interval) {
      this.$interval = $interval;
    }
    $onInit() {
      this.duration = parseInt(this.duration || 300, 10);
      this.repetitions = parseInt(this.repetitions || 2, 10);
      if (this.isVertical) {
        this.initVertical();
      } else {
        this.initHorizontal();
      }
    }
    $onDestroy() {
      this.$interval.cancel(this.interval);
    }
    initVertical() {
      let level = 0,
          step = 0,
          odd = false,
          repetition = 0;
      const func = () => {
        this.y = 50 + (10 + 20 * level) * (odd ? 1 : -1);
        this.x = step * 12.5;
        step++;
        if (step === 8) {
          step = 0;
          level++;
        }
        if (level === 3) {
          repetition++;
          level = 0;
        }
        if (repetition === this.repetitions) {
          this.$interval.cancel(this.interval);
          this.x = 50;
          this.y = 50;
          this.then({$data: 1});
        }
        odd = !odd;
      };
      func();
      this.interval = this.$interval(func, this.duration);
    }
    initHorizontal() {
      let level = 0,
          step = 0,
          odd = false,
          repetition = 0;
      const func = () => {
        this.x = 50 + (10 + 20 * level) * (odd ? 1 : -1);
        this.y = step * 12.5;
        step++;
        if (step === 8) {
          step = 0;
          level++;
        }
        if (level === 3) {
          repetition++;
          level = 0;
        }
        if (repetition === this.repetitions) {
          this.$interval.cancel(this.interval);
          this.x = 50;
          this.y = 50;
          this.then({$data: 1});
        }
        odd = !odd;
      };
      func();
      this.interval = this.$interval(func, this.duration);
    }
  }
  angular
    .module('excersise.warmup.excersise.component', [])
    .component('warmupExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/warmup/excersise/component/component-excersise-warmup.route.html',
      bindings: {
        duration: '<',
        repetitions: '<',
        isVertical: '<',
        then: '&'
      }
    });
}());
