(function () {
  'use strict';
  class Controller {
    constructor($timeout, $window, $firedux, $location) {
      this._ = $window._;
      this.math = $window.math;
      this.$timeout = $timeout;
      this.$firedux = $firedux;
      this.$params = $location.$$search;
    }
    $get(obj, val) {
      let returnable = 0;
      if (obj && val) {
        returnable = this.math.eval(val, obj);
      }
      return returnable;
    }
    $divide(n, i) {
      return Math.ceil(n / i);
    }
    $parseInitialParams(params) {
      return this._.mapObject(params, item => item.default);
    }
    $receiveSavedParams(params) {
      this.$$params = this._.mapObject(this.$$params, (val, paramId) => this._.isUndefined((params || {})[paramId]) ? val : (params || {})[paramId]);
    }
    $saveParam(paramId, val) {
      return this.$firedux.ref(`students/${this.$uid}/params/${this.lessonId}/${paramId}`).set(val);
    }
    $bumpParams() {
      this._.each(this.$lesson.params, (param, paramId) => {
        if (param.autoincrease) {
          const newVal = this.$$params[paramId] + (param.step || 1);
          this.$saveParam(paramId, newVal > param.max ? param.min : newVal);
        }
      });
    }
  }
  angular
    .module('lesson.lesson', [])
    .component('lessonLesson', {
      controller: Controller,
      templateUrl: 'root/lesson/lesson/lesson-lesson.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('lesson.lesson', {
          abstract: false,
          url: '/lesson?initial',
          template: '<lesson-lesson/>'
        });
    });
}());
