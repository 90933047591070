(function () {
  'use strict';
  angular
    .module('excersise.word-pyramid', [
      'excersise.word-pyramid.splash',
      'excersise.word-pyramid.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/wordPyramid', '/excersise/word-pyramid/splash');
      $urlRouterProvider.when('/excersise/word-pyramid', '/excersise/word-pyramid/splash');
      $stateProvider
        .state('excersise.word-pyramid', {
          abstract: false,
          url: '/word-pyramid',
          template: '<ui-view/>'
        });
    });
}());
