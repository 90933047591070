(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('root', [
      // Components
      'activePauses',
      'profile',
      'currentLesson',
      // Data
      'hit',
      'level',
      'articles',
      'phrases',
      'sessions',
      // Routes
      'home',
      'account',
      'excersise',
      'session',
      'lesson',
      'group'
    ])
    .component('rootEl', {
      controller: Controller,
      templateUrl: 'root/root.comp.html'
    });
}());
