(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this.$window = $window;
    }
    $onInit() {
      const apiKey = '4Vj8eK4rloUd272L48hsrarnUA', merchantId = '508029', accountId = '512321', amount = '100000', currency = 'COP';
      let referenceCode = `${this.auth.uid}${Date.now()}`;
      this.$params = {
        test: '1',
        tax: amount * 0.19,
        taxReturnBase: amount * 0.81,
        email: this.auth.email,
        currency,
        amount,
        merchantId,
        accountId,
        referenceCode,
        signature: this.getSignature({apiKey, merchantId, referenceCode, amount, currency}),
        confirmationUrl: 'https://us-central1-intelligere-94e9b.cloudfunctions.net/accountPayment?email=' + this.$window.encodeURIComponent(this.auth.email)
      };
    }
    getSignature({apiKey, merchantId, referenceCode, amount, currency}) {
      return this.$window.md5(`${apiKey}~${merchantId}~${referenceCode}~${amount}~${currency}`);
    }
  }
  angular
    .module('actions')
    .component('membershipSubscribe', {
      controller: Controller,
      templateUrl: 'actions/membership/subscribe/subscribe-membership.action.html',
      bindings: {
        auth: '<',
        then: '&',
        catch: '&',
        group: '&'
      }
    });
}());
