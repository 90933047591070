(function () {
  'use strict';
  class Controller {
  }
  angular
    .module('home.levels.level2', [
      'home.levels.level2.excersises'
    ])
    .component('homeLevelsLevel2', {
      controller: Controller,
      templateUrl: 'root/home/_components/levels/level2/level2-levels-home.comp.html',
      bindings: {
        isActive: '<'
      }
    });
}());
