(function () {
  'use strict';
  angular
    .module('excersise.mergingColumns', [
      'excersise.mergingColumns.splash',
      'excersise.mergingColumns.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/mergingColumns', '/excersise/mergingColumns/splash');
      $stateProvider
        .state('excersise.mergingColumns', {
          abstract: false,
          url: '/mergingColumns',
          template: '<ui-view/>'
        });
    });
}());
