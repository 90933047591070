(function () {
  'use strict';
  angular
    .module('excersise.speed', [
      'excersise.speed.splash',
      'excersise.speed.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/speed?level', '/excersise/speed/splash?level');
      $stateProvider
        .state('excersise.speed', {
          abstract: false,
          url: '/speed?level',
          template: '<ui-view/>'
        });
    });
}());
