<fd-auth then="$ctrl.$auth = $data"><then><fd-user then="$ctrl.$user = $data"></fd-user></then></fd-auth><auth-login ng-if="$ctrl.LOGIN" then="$ctrl.LOGIN = false" catch="$ctrl.LOGIN = false" register="
    $ctrl.LOGIN = false;
    $ctrl.REGISTER = true;"></auth-login><auth-register ng-if="$ctrl.REGISTER" then="$ctrl.REGISTER = false" catch="$ctrl.REGISTER = false" login="
    $ctrl.REGISTER = false;
    $ctrl.LOGIN = true;"></auth-register><membership-subscribe ng-if="$ctrl.SUBSCRIBE" then="$ctrl.SUBSCRIBE = false" catch="$ctrl.SUBSCRIBE = false" group="
    $ctrl.SUBSCRIBE = false;
    $ctrl.GROUP = true;"></membership-subscribe><membership-group ng-if="$ctrl.GROUP" then="$ctrl.GROUP = false" catch="$ctrl.GROUP = false" subscribe="
    $ctrl.GROUP = false;
    $ctrl.SUBSCRIBE = true;"></membership-group><div layout="row"><!--md-button(ng-if="$ctrl.$auth && $ctrl.$user.isMember && !$ctrl.isExpanded"
ng-click="$ctrl.isExpanded = true; $ctrl.then({$data: true});")
|VER MÁS--><!--md-button(ng-if="$ctrl.$auth && $ctrl.$user.isMember && $ctrl.isExpanded"
ng-click="$ctrl.isExpanded = false; $ctrl.then({$data: false});")
|VER MENOS--><!--md-button.md-primary.md-hue-1(ng-if="$ctrl.$auth && !$ctrl.$user.isMember"
ng-click="$ctrl.GROUP = true")
|CÓDIGO DE CLASE--><!--md-button.md-primary.md-raised(ng-if="$ctrl.$auth && !$ctrl.$user.isMember"
ng-click="$ctrl.SUBSCRIBE = true")
|SUSCRIBIRME--><md-button flex="flex" style="margin: 0 1rem" ng-if="!$ctrl.$auth" ng-click="$ctrl.REGISTER = true" class="md-primary md-raised">CREAR CUENTA</md-button></div>