(function () {
  'use strict';
  class Controller {
    constructor() {
      this.$today = new Date();
      this.$year = new Date();
      this.$year.setFullYear(this.$year.getFullYear() + 1);
    }
  }
  angular
    .module('actions')
    .component('paymentsMake', {
      controller: Controller,
      templateUrl: 'actions/payments/make/make-payments.action.html',
      bindings: {
        then: '&',
        catch: '&'
      }
    });
}());
