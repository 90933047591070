(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this._ = $window._;
      this.PrismicJS = $window.PrismicJS;
      this.PrismicDOM = $window.PrismicDOM;
    }
    $onInit() {
      this.$firedux.val('ARTICLES').watch(articles => {
        if (!articles) {
          this.$getUserTags()
            .then(userTags => this.$getPrismic(userTags))
            .then(result =>
              this.$firedux.val('ARTICLES').set(result));
        } else {
          this.$firedux.$apply();
          this.emitRandomArticle(articles);
        }
      });
    }
    emitRandomArticle(articles) {
      const {content, questions} = articles[this._.random(0, articles.length - 1)];
      this.then({
        $questions: questions,
        $article: {content}
      });
    }
    $getUserTags() {
      return new Promise((resolve, reject) => {
        this.$firedux.waitForAuth(() => {
          const userId = this.$firedux.auth.uid;
          this.$firedux.ref(`users/${userId}/registration/tags`).once('value').then(s => {
            resolve(s.val());
          }).catch(reject);
        });
      });
    }
    $getAllDocs(api, userTags) {
      const _ = this._,
          getPage = (page) => api.query([
            this.PrismicJS.Predicates.at('document.type', 'article'),
            ...(userTags ? this.PrismicJS.Predicates.any('document.tags', userTags) : [])
          ], {
            pageSize: 100,
            page
          }).then(({results}) => results);
      return Promise.all([
        getPage(1),
        getPage(2),
        getPage(3),
        getPage(4),
        getPage(5),
        getPage(6),
        getPage(7),
        getPage(8),
        getPage(9),
        getPage(10)
      ]).then(_.flatten);
    }
    $getPrismic(userTags) {
      const _ = this._;
      var apiEndpoint = 'https://intelligere.prismic.io/api/v2';
      this.$loading = true;
      return this.PrismicJS.getApi(apiEndpoint)
      .then(api => this.$getAllDocs(api, userTags))
      .then(results => _(results).map(({data: {title, text, body}} = {}) => ({title, text, body})))
      .then(results => this.maxWords ? _.filter(results, ({text}) => this.PrismicDOM.RichText.asText(text).split(' ').length <= this.maxWords) : results)
      .then(results => _(results).map(({title, text, body}) => ({
        content: {
          title: this.PrismicDOM.RichText.asText(title),
          body: this.PrismicDOM.RichText.asHtml(text)
        },
        questions: _(body).map(({primary, items}) => ({
          statement: primary.text,
          responses: _(items).map(({answer}) => ({text: answer})),
          correctResponse: _(items).chain().map(({correct}, index) => ({
            correct: correct === 'correct',
            index
          })).filter(({correct}) => correct).map(({index}) => index).value()[0]
        }))
      })))
      .then($data => $data);
    }
  }
  angular
    .module('articles', [])
    .component('itArticles', {
      controller: Controller,
      bindings: {
        then: '&',
        maxWords: '<'
      }
    });
}());
