(function () {
  'use strict';
  class Controller {
    constructor() {
      this.$credentials = {};
    }
    $onInit() {
      this.$credentials.email = this.email || '';
    }
  }
  angular
    .module('actions')
    .component('authForgot', {
      controller: Controller,
      templateUrl: 'actions/auth/forgot/forgot-auth.action.html',
      bindings: {
        then: '&',
        email: '<',
        catch: '&',
        login: '&'
      }
    });
}());
