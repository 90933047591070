(function () {
  'use strict';
  function reducer(action, $firedux) {
    const {credentials} = action || {},
        {email, password} = credentials || {};
    return $firedux.firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(user => user.sendEmailVerification().then(() => user.uid));
  }
  angular
    .module('reducers')
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'CLIENT.AUTH.REGISTER',
      reducer: function (action) {
        return reducer(action, $firedux);
      }
    });
  }
}());
