(function () {
  'use strict';
  class Controller {
    constructor() {}
    $rand(max) {
      return Math.floor(Math.random() * max);
    }
  }
  angular
    .module('excersise.doubleColumn.excersise', [
      'excersise.doubleColumn.excersise.component'
    ])
    .component('excersiseDoubleColumnExcersise', {
      controller: Controller,
      templateUrl: 'root/excersise/doubleColumn/excersise/excersise-doubleColumn-excersise.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('excersise.doubleColumn.excersise', {
          abstract: false,
          url: '/excersise',
          template: '<excersise-double-column-excersise/>'
        });
    });
}());
