(function () {
  'use strict';
  function getPercentage(score, min, max) {
    return (score - min) / (max - min);
  }
  const POINTS = 10;
  class Controller {
    constructor($timeout, $window, $intelligere, $firedux) {
      this.$intelligere = $intelligere;
      this.$firedux = $firedux;
      this.startTime = Date.now();
    }
    $then(ctx) {
      const {$data, $wpm, $comprehension} = ctx;
      ctx.$points = Math.floor(this.$getPoints(ctx, this.lesson.unit));
      if (ctx.$points < 0) {
        ctx.$points = 0;
      }
      if (ctx.$points > POINTS) {
        ctx.$points = POINTS;
      }
      this.saveProgress(this.lessonId, $data, this.levelId, $wpm, $comprehension, this.lesson, parseInt(this.params.sublevel, 10), ctx.$points);
      this.then(ctx);
    }
    $getPoints(ctx, unit) {
      const {$data, $wpm, $comprehension} = ctx;
      switch (unit) {
        case 'wpm':
          return getPercentage($wpm * $comprehension, 0, 800) * POINTS;
        case 'milliseconds':
          return getPercentage($data, 9000, 1500) * POINTS;
        case 'percentage':
          return $data * POINTS;
        case 'number':
          return getPercentage($data, 0, 50) * POINTS;
        default:
          return 0.3 * POINTS;
      }
    }
    saveProgress(lessonId, score, levelId, wpm, comprehension, {unit}, sublevel, points) {
      this.$intelligere.logLession({
        preventLog: this.preventLog,
        studentId: this.$firedux.auth.uid,
        lessonId,
        levelId,
        duration: Date.now() - this.startTime,
        wpm,
        comprehension,
        sublevel,
        percentage: unit === 'percentage' ? score : null,
        milliseconds: unit === 'milliseconds' ? score : null,
        number: unit === 'num' + 'ber' ? score : null,
        points
      });
    }
  }
  angular
    .module('currentLesson', [])
    .component('currentLesson', {
      controller: Controller,
      templateUrl: 'root/_components/currentLesson/currentLesson.comp.html',
      bindings: {
        preventLog: '<',
        lessonId: '@',
        lesson: '<',
        params: '<',
        level: '<',
        then: '&'
      }
    });
}());
