(function () {
  'use strict';
  angular
    .module('excersise', [
      // Global Components
      'excersise.questions',
      // Level 1
      'excersise.speed',
      'excersise.warmup',
      'excersise.numbersWarmup',
      'excersise.numbersWarmup2',
      'excersise.numbersWarmup3',
      'excersise.numbersWarmup4',
      'excersise.word-pyramid',
      'excersise.truncated',
      'excersise.findWord',
      'excersise.recognize',
      // Level 2
      'excersise.disappearingWords',
      'excersise.absentWords',
      'excersise.wordRetention',
      'excersise.similarWords',
      'excersise.findNumbers',
      // Level 3
      'excersise.singleColumn',
      'excersise.wordInParagraph',
      'excersise.wordDiamond',
      'excersise.truncatedText',
      'excersise.diamondColumn',
      // Level4
      'excersise.doubleColumn',
      'excersise.tripleColumn',
      'excersise.mergingColumns',
      'excersise.arrowsWarmup',
      'excersise.zigzagText',
      'excersise.doubleFixation'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise', '/404');
      $stateProvider
        .state('excersise', {
          abstract: false,
          url: '/excersise',
          template: '<ui-view/>'
        });
    });
}());
