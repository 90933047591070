(function () {
  'use strict';
  angular
    .module('excersise.flashingText', [
      'excersise.flashingText.splash',
      'excersise.flashingText.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/flashingText?level', '/excersise/flashingText/splash?level');
      $stateProvider
        .state('excersise.flashingText', {
          abstract: false,
          url: '/flashingText?level',
          template: '<ui-view/>'
        });
    });
}());
