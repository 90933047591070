(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('home.account', [
      'home.account.message',
      'home.account.toolbar'
    ])
    .component('homeAccount', {
      controller: Controller,
      templateUrl: 'root/home/_components/account/account-home.comp.html'
    });
}());
