(function () {
  'use strict';
  class Controller {
    constructor($window, $timeout, $state) {
      this._ = $window._;
      this.$timeout = $timeout;
      this.$state = $state;
    }
    $onInit() {
      this.initTimestamp = Date.now();
      delete this.phrases.key;
      this.content = this.parseContent(this.phrases);
      this.opacity = parseFloat(this.opacity || 0.85, 10);
      this.height = parseFloat(this.height || 40, 10);
      this.timeout = this.$timeout(() => {
        this.$canContinue = true;
      }, this.getMinTime());
    }
    $onDestroy() {
      this.$timeout.cancel(this.timeout);
    }
    getWords() {
      const _ = this._;
      return _(this.content)
        .reduce((memo, phrase) => {
          return phrase.length + memo;
        }, 0);
    }
    getMinTime() {
      let words = this.getWords(),
          minWpm = 2000,
          millPerMins = 60000;
      return millPerMins * words / minWpm;
    }
    parseContent(phrases) {
      const _ = this._;
      return _(phrases)
        .map((phrase = {}) => {
          return phrase.content.split(' ');
        });
    }
    $then() {
      const words = this.getWords(),
          minutes = (Date.now() - this.initTimestamp) / 60000;
      this.then({
        $data: words / minutes
      });
    }
  }
  angular
    .module('excersise.truncatedText.excersise.component', [])
    .component('truncatedTextExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/truncatedText/excersise/component/component-excersise-truncatedText.comp.html',
      bindings: {
        opacity: '<',
        height: '<',
        phrases: '<',
        title: '<',
        then: '&'
      }
    });
}());
