(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $then(questions, answers) {
      const _ = this._;
      let data = _(answers)
        .chain()
        // Map answers to 1 or 0
        .map((answer, index) => answer === questions[index].correctResponse ? 1 : 0)
        // Sum all answers
        .reduce((memo, num) => memo + num, 0)
        // Divide by length to get average
        .value() / questions.length;
      this.then({
        $data: data === 0 ? 0.001 : data
      });
    }
  }
  angular
    .module('excersise.questions', [])
    .component('excersiseQuestions', {
      controller: Controller,
      templateUrl: 'root/excersise/_components/questions/questions-excersise.comp.html',
      bindings: {
        questions: '<',
        then: '&'
      }
    });
}());
