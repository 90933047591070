(function () {
  'use strict';
  class Controller {
    constructor($timeout) {
      this.$timeout = $timeout;
      this.init = Date.now();
    }
    $onInit() {
      let length = this.article.content.body.split(' ').length,
          maxWpm = 2000,
          maxMins = length / maxWpm,
          maxTime = maxMins * 60000;
      this.$timeout(() => {
        this.$canContinue = true;
      }, maxTime);
    }
    $then(article) {
      let mins = (Date.now() - this.init) / (1000 * 60),
          length = article.split(' ').length,
          wpm = length / mins;
      this.then({
        $data: wpm
      });
    }
  }
  angular
    .module('excersise.singleColumn.excersise.component', [])
    .component('singleColumnExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/singleColumn/excersise/component/component-excersise-singleColumn.comp.html',
      bindings: {
        article: '<',
        then: '&'
      }
    });
}());
