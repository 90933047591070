(function () {
  'use strict';
  angular
    .module('excersise.doubleColumn', [
      'excersise.doubleColumn.splash',
      'excersise.doubleColumn.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/doubleColumn', '/excersise/doubleColumn/splash');
      $stateProvider
        .state('excersise.doubleColumn', {
          abstract: false,
          url: '/doubleColumn',
          template: '<ui-view/>'
        });
    });
}());
