(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this._ = $window._;
      this.PrismicJS = $window.PrismicJS;
      this.PrismicDOM = $window.PrismicDOM;
    }
    $onInit() {
      const {_} = this;
      if (this.singleArticle) {
        this.$firedux.val('ORDERED_PHRASES').watch((articles) => {
          if (!articles) {
            this.$getUserTags()
              .then(userTags => this.$getPrismic(userTags))
              .then(results => _(results).map(({phrases, title, questions}) => ({
                $phrases: _(phrases)
                  .chain()
                  .filter(result => result.length > 0)
                  .map(content => ({
                    content,
                    length: content.length
                  })).value(),
                $title: title,
                $questions: questions
              })))
              .then(result =>
                this.$firedux.val('ORDERED_PHRASES').set(result));
          } else {
            this.$firedux.$apply();
            this.then(_(articles).sample());
          }
        });
      } else {
        this.$firedux.val('PHRASES').watch((phrases) => {
          if (!phrases) {
            this.$getPrismic()
              .then(results => _(results).pluck('phrases'))
              .then(_.flatten)
              .then(results => _(results).filter(result => result.length > 0))
              .then(results => _(results).map(content => ({
                content,
                length: content.length
              })))
              .then(result =>
                this.$firedux.val('PHRASES').set(result));
          } else {
            this.$firedux.$apply();
            this.then({$data: phrases});
          }
        });
      }
    }
    $getUserTags() {
      const userId = this.$firedux.auth.uid;
      return this.$firedux.ref(`users/${userId}/registration/tags`).once('value').then(s => s.val());
    }
    $getPrismic(userTags) {
      const _ = this._;
      var apiEndpoint = 'https://intelligere.prismic.io/api/v2';
      this.$loading = true;
      return this.PrismicJS.getApi(apiEndpoint)
      .then((api) => api.query([
        this.PrismicJS.Predicates.at('document.type', 'article'),
        ...(userTags ? this.PrismicJS.Predicates.any('document.tags', userTags) : [])
      ]))
      .then(({results}) => results)
      .then(results => _(results).map(({data: {title, text, body}} = {}) => ({title, text: text || '', body})))
      .then(results => this.maxWords ? _.filter(results, ({text}) => this.PrismicDOM.RichText.asText(text).split(' ').length <= this.maxWords) : results)
      // .then(results => console.log(_.map(results, ({text}) => this.PrismicDOM.RichText.asText(text).split(' ').length)) || results)
      .then(results => _(results).map(({title, text: article, body}) => ({
        phrases: _(article).map(({text}) => text),
        title: this.PrismicDOM.RichText.asText(title),
        questions: _(body).map(({primary, items}) => ({
          statement: primary.text,
          responses: _(items).map(({answer}) => ({text: answer})),
          correctResponse: _(items).chain().map(({correct}, index) => ({
            correct: correct === 'correct',
            index
          })).filter(({correct}) => correct).map(({index}) => index).value()[0]
        }))
      })));
    }
  }
  angular
    .module('phrases', [])
    .component('itPhrases', {
      controller: Controller,
      bindings: {
        maxWords: '<',
        singleArticle: '@',
        then: '&'
      }
    });
}());
