(function () {
  'use strict';
  const BLOCK_AMOUNT = 10,
      WORDS_PER_BLOCK = 2;
  class Controller {
    constructor($window, $timeout, $interval) {
      this._ = $window._;
      this.$timeout = $timeout;
      this.$interval = $interval;
    }
    $onInit() {
      this.currentBlock = 0;
      this.blockAmount = parseInt(this.blockAmount || BLOCK_AMOUNT, 10);
      this.wordsPerBlock = parseInt(this.wordsPerBlock || WORDS_PER_BLOCK, 10);
      this.wordAmount = this.blockAmount * this.wordsPerBlock;
      this.interval = parseInt(this.interval || 1000, 10);
    }
    $processWords(words = {}) {
      let time, interval;
      this.words = words;
      this.test = [];
      this.dictionary = [];
      for (let i = 0; i < this.blockAmount; i++) {
        this.dictionary[i] = [];
        for (let j = 0; j < this.wordsPerBlock; j++) {
          this.addUnique(this.dictionary[i], words);
          this.addUnique(this.test, words);
        }
      }
      // Make test half its size
      this.test = this._(this.test)
        .chain()
        .shuffle()
        .first(this.wordAmount / 2)
        .value();
      // Merge test and dictionary
      this.test = this._.chain(this.test).union(this._.chain(this.dictionary).flatten().shuffle().first(this.wordAmount / 2).value()).shuffle().value();
      // Set minimum time to continue
      time = this.wordsPerBlock * this.interval;
      interval = this.$interval(() => {
        this.currentBlock++;
      }, time);
      this.$timeout(() => {
        this.$canContinue = true;
        this.$interval.cancel(interval);
      }, time * this.blockAmount);
    }
    addUnique(array, dictionary) {
      if (angular.isArray(array) && angular.isObject(dictionary)) {
        let word = this._(dictionary).sample();
        if (this._(array).indexOf(word) > 0 && word.length < 10) {
          this.addUnique(array, dictionary);
        } else {
          array.push(word);
        }
      }
    }
    $choose(chosen, word) {
      if (
        this._.chain(chosen).compact().size().value() < this.wordAmount / 2 &&
        !chosen[word]
      ) {
        chosen[word] = true;
      } else {
        chosen[word] = false;
      }
    }
    $verify(chosen, dictionary) {
      let total = this.wordAmount / 2,
          selected = [],
          percentage,
          correct,
          incorrect,
          missing;
      this._(chosen).each((val, key) => {
        if (val) {
          selected.push(key);
        }
      });
      correct = this._.intersection(selected, this._.flatten(dictionary));
      incorrect = this._.difference(selected, this._.flatten(dictionary));
      missing = this._.difference(this._.intersection(this.test, this._.flatten(dictionary)), selected);
      percentage = correct.length / total;
      return this.then({
        $data: percentage,
        $correct: correct,
        $incorrect: incorrect,
        $missing: missing
      });
    }
  }
  angular
    .module('excersise.recognize.excersise.component', [])
    .component('recognizeExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/recognize/excersise/component/component-excersise-recognize.route.html',
      bindings: {
        wordsPerBlock: '<',
        blockAmount: '<',
        interval: '<',
        then: '&'
      }
    });
}());
