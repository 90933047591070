(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this.order = $window._.shuffle([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    }
  }
  angular
    .module('home.levels.level3.excersises', [])
    .component('levelsLevel3Excersises', {
      controller: Controller,
      templateUrl: 'root/home/_components/levels/level3/excersises/excersises-level3-levels.comp.html'
    });
}());
