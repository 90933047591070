(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('actions')
    .component('membershipGroup', {
      controller: Controller,
      templateUrl: 'actions/membership/group/group-membership.action.html',
      bindings: {
        then: '&',
        catch: '&',
        subscribe: '&'
      }
    });
}());
