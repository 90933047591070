(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
      this.initTimestamp = Date.now();
    }
    $onInit() {
      this.repetitions = parseInt(this.repetitions || 10, 10);
      this.content = this.parsePhrases(this.phrases);
      this.current = 0;
      this.tick();
    }
    tick() {
      this.dictionary = this.content.selectedPhrases[this.current];
      this.currentWord = this.content.selectedWords[this.current];
    }
    parsePhrases(phrases) {
      const _ = this._;
      let repetitions = this.repetitions,
          selectedWords = [],
          selectedPhrases;
      selectedPhrases = _(phrases)
        .chain()
        .filter((phrase = {}) => {
          return phrase.length > 300 && phrase.length < 450;
        })
        .sample(repetitions)
        .map((phrase, index) => {
          let phraseArray = phrase.content.split(' ');
          selectedWords[index] = _(phraseArray)
            .chain()
            .filter(word => {
              return word.indexOf(',') === -1 && word.indexOf('.') === -1 && word.length > 5;
            })
            .sample()
            .value();
          return phraseArray;
        })
        .value();
      return {
        selectedWords,
        selectedPhrases
      };
    }
    $verifyWord(word) {
      const current = this.current,
          content = this.content;
      if (word === content.selectedWords[current]) {
        this.current += 1;
        this.tick();
      }
      if (this.current === this.repetitions) {
        this.totalTime = Date.now() - this.initTimestamp;
        this.then({
          $data: this.totalTime
        });
      }
    }
  }
  angular
    .module('excersise.wordInParagraph.excersise.component', [])
    .component('wordInParagraphExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/wordInParagraph/excersise/component/component-excersise-wordInParagraph.comp.html',
      bindings: {
        phrases: '<',
        repetitions: '<',
        then: '&'
      }
    });
}());
