(function () {
  'use strict';
  class Controller {
    constructor($timeout, $window) {
      const AVERAGE_LETTER_WIDTH = 10;
      let canvasWidth;
      this.$timeout = $timeout;
      this._ = $window._;
      canvasWidth = $window.innerWidth < 600 - 32 ? $window.innerWidth - 32 : 600 - 32;
      this.horizontalLetters = canvasWidth / AVERAGE_LETTER_WIDTH;
    }
    $onInit() {
      const _ = this._;
      this.MAX_COLUMNS = 80;
      let columnWidth = this.horizontalLetters / 2 + 4 - this.MAX_COLUMNS / 2,
          currentColumn = {
            length: columnWidth,
            number: 0
          },
          columns;
      this.words = _(this.phrases)
        .chain()
        .map(phrase => phrase.content.split(' '))
        .each(phraseWords => {
          phraseWords[phraseWords.length - 1] += '.';
        })
        .flatten()
        .value();
      columns = _(this.words)
        .groupBy(word => {
          if (currentColumn.length < word.length) {
            let delta = Math.floor((this.MAX_COLUMNS - currentColumn.number) / 2);
            columnWidth = this.horizontalLetters / 2 + 4 - delta;
            currentColumn.length = columnWidth;
            currentColumn.number += 1;
          }
          currentColumn.length -= word.length;
          return currentColumn.number > this.MAX_COLUMNS ? this.MAX_COLUMNS + 1 : currentColumn.number;
        })
        ;
      this.columns = columns;
      this.$setTimeout();
    }
    $setTimeout() {
      const MIN_WPM = 2000;
      let totalWords = this.words.length,
          minMinutes = totalWords / MIN_WPM,
          minTime = minMinutes * 60000;
      this.timeout = this.$timeout(() => {
        this.$canContinue = true;
      }, minTime);
      this.init = Date.now();
    }
    $ceil(i) {
      return Math.ceil(i);
    }
    $then() {
      let totalWords = this.words.length,
          totalMilliseconds = Date.now() - this.init,
          totalMinutes = totalMilliseconds / 60000,
          wpm = totalWords / totalMinutes;
      this.then({
        $data: wpm
      });
    }
  }
  angular
    .module('excersise.mergingColumns.excersise.component', [])
    .component('mergingColumnsExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/mergingColumns/excersise/component/component-excersise-mergingColumns.comp.html',
      bindings: {
        title: '<',
        phrases: '<',
        then: '&'
      }
    });
}());
