(function () {
  'use strict';
  class Controller {
    constructor($window, $state) {
      this._ = $window._;
      this.$state = $state;
      this.$chosen = {};
    }
    $onInit() {
      this.dimension = this.dimension || 5;
      try {
        this.content = this.parsePhrases(this.phrases);
      } catch (e) {
        this.$state.reload();
      }
      this.initTimestamp = Date.now();
    }
    $processWords(words) {
      const {_} = this;
      this.correctWords = _(this.content)
        .chain()
        .flatten()
        .filter(i => i.length > 5)
        .map(str => _.reduce(
          [',', '"', '"', '!', '¡', '?', '?', '(', ')', '\'', '\'', '-', '.', '”', '–'],
          (memo, item) => memo.replace(item, ''), str))
        .uniq()
        .sample(10)
        .value();
      this.incorrectWords = _(words).chain().filter(i => i.length > 5).sample(10).value();
      this.allWords = _([...this.correctWords, ...this.incorrectWords]).shuffle();
    }
    parsePhrases(phrases) {
      const _ = this._;
      let dimension = this.dimension,
          wordArray,
          parsedPhrase;
      wordArray = _(phrases)
        .chain()
        .map((phrase = {}) => {
          return phrase.content.split(' ');
        })
        .filter((phraseList) => {
          return phraseList.length > dimension * dimension - 1 && phraseList.length < dimension * dimension + 2;
        })
        .sample()
        .value();
      parsedPhrase = this.parsePhrase(wordArray, dimension);
      return parsedPhrase;
    }
    parsePhrase(wordArray, dimension = 5) {
      const _ = this._;
      let range = _.range(2 * dimension - 1),
          restArray = [];
      return _(range)
        .chain()
        .map((index) => {
          let first = index >= dimension ?
            2 * dimension - 2 - index :
            index;
          first += 1;
          restArray[index] = (restArray[index - 1] || 0) + first;
          return {
            first,
            rest: restArray[index - 1] || 0
          };
        })
        .map(({rest, first}) => {
          return _(wordArray)
            .chain()
            .rest(rest)
            .first(first)
            .value();
        })
        .value();
    }
    $choose(chosen, word) {
      const length = this.allWords.length;
      if (
        this._.chain(chosen).compact().size().value() < length / 2 &&
        !chosen[word]
      ) {
        chosen[word] = word;
      } else {
        chosen[word] = false;
      }
    }
    $then() {
      let chosenArray = this._(this.$chosen).chain().toArray().compact().value(),
          correct = this._.intersection(this.correctWords, chosenArray),
          incorrect = this._.difference(chosenArray, this.correctWords),
          missing = this._.difference(this.correctWords, chosenArray);
      if (correct.length / this.correctWords.length) {
        this.then({
          $data: correct.length / this.correctWords.length,
          $correct: correct,
          $incorrect: incorrect,
          $missing: missing
        });
      }
    }
  }
  angular
    .module('excersise.wordDiamond.excersise.component', [])
    .component('wordDiamondExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/wordDiamond/excersise/component/component-excersise-wordDiamond.comp.html',
      bindings: {
        phrases: '<',
        dimension: '<',
        then: '&'
      }
    });
}());
