(function () {
  'use strict';
  angular
    .module('excersise.numbersWarmup2', [
      'excersise.numbersWarmup2.excersise.component'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/numbersWarmup2', '/excersise/numbersWarmup2/splash');
      $stateProvider
        .state('excersise.numbersWarmup2', {
          abstract: false,
          url: '/numbersWarmup2',
          template: '<ui-view/>'
        });
    });
}());
