(function () {
  'use strict';
  class Controller {
    constructor($window, $interval) {
      this._ = $window._;
      this.$interval = $interval;
      this.$words = ['', '', '', '', ''];
      this.sampledWords = [];
    }
    $processWords(words = {}) {
      let wordMap = [];
      this._(words).map(word => {
        if (!wordMap[word.length]) {
          wordMap[word.length] = [];
        }
        wordMap[word.length].push(word);
      });
      this.allWords = words;
      this.$start(wordMap);
    }
    $start(wordMap) {
      this.$cycles = [];
      this.$currentWord = 0;
      this.$currentCycle = 0;
      for (let i = 0; i < parseInt(this.cycles || 2, 10); i++) {
        this.$cycles.push(this.getSample(wordMap));
      }
      this.interval = this.$interval(() => {
        this.addWord();
      }, parseInt(this.period || 1000, 10));
    }
    getSample(wordMap) {
      let samples = [];
      for (let i = 3; i <= 9; i++) {
        let sample = this._(wordMap[i]).sample();
        while (this._.includes(this.sampledWords, sample)) {
          sample = this._(wordMap[i]).sample();
        }
        samples.push(sample);
        this.sampledWords.push(sample);
      }
      return samples;
    }
    addWord() {
      this.$currentWord++;
      if (this.$currentWord > 7) {
        this.$currentWord = 0;
        this.$currentCycle++;
      }
      if (this.$currentCycle >= parseInt(this.cycles || 2, 10)) {
        this.$interval.cancel(this.interval);
        this.test(this._(this.$cycles).flatten());
      }
    }
    test(words) {
      this.total = 0.5;
      this.amount = parseInt(this.cycles || 2, 10) * 7;
      this.dictionary = this._(words).chain().shuffle().sample(Math.floor(this.amount)).value();
      this.testWords(this.allWords);
    }
    testWords(words) {
      let goodWords = this.parseWords(words),
          sample = this._(goodWords).sample(this.amount);
      this.goodWords = sample;
      this.$dictionary = this._.shuffle(this._.union(this.dictionary, sample));
    }
    $length(object) {
      return this._(object).size();
    }
    $choose(chosen, word, length) {
      if (
        this._.chain(chosen).compact().size().value() < length / 2 &&
        !chosen[word]
      ) {
        chosen[word] = true;
      } else {
        chosen[word] = false;
      }
    }
    $verify(chosen) {
      let chosenArray = this._.compact(this._.map(chosen, (item, key) => item ? key : '')),
          correct = this._.intersection(this.dictionary, chosenArray),
          incorrect = this._.difference(chosenArray, this.dictionary),
          missing = this._.difference(this.dictionary, chosenArray);
      if (chosenArray.length / this.dictionary.length) {
        this.then({
          $data: correct.length / this.dictionary.length,
          $correct: correct,
          $incorrect: incorrect,
          $missing: missing
        });
      }
    }
    parseWords(words) {
      let returnable = [];
      this._(words).map(word => {
        if (word.length >= 3 && word.length <= 7) {
          returnable.push(word);
        }
      });
      return returnable;
    }
  }
  angular
    .module('excersise.word-pyramid.excersise.component', [])
    .component('wordPyramidExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/word-pyramid/excersise/component/component-excersise-word-pyramid.route.html',
      bindings: {
        period: '<',
        cycles: '<',
        then: '&'
      }
    });
}());
