(function () {
  'use strict';
  class Controller {
    constructor() {
      this.POINTS_PER_LEVEL = 500;
    }
    floor(i) {
      return Math.floor(i);
    }
  }
  angular
    .module('home.levels.progress', [])
    .component('homeLevelsProgress', {
      controller: Controller,
      templateUrl: 'root/home/_components/levels/progress/progress-levels-home.comp.html',
      bindings: {
        level: '<',
        then: '&'
      }
    });
}());
