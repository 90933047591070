(function () {
  'use strict';
  angular
    .module('excersise.similarWords', [
      'excersise.similarWords.splash',
      'excersise.similarWords.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/similarWords', '/excersise/similarWords/splash');
      $stateProvider
        .state('excersise.similarWords', {
          abstract: false,
          url: '/similarWords',
          template: '<ui-view/>'
        });
    });
}());
