(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this.order = $window._.shuffle([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    }
  }
  angular
    .module('home.levels.level5.excersises', [])
    .component('levelsLevel5Excersises', {
      controller: Controller,
      templateUrl: 'root/home/_components/levels/level5/excersises/excersises-level5-levels.comp.html'
    });
}());
