(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('account.subscription', [])
    .component('accountSubscription', {
      controller: Controller,
      templateUrl: 'root/account/_components/subscription/subscription-account.comp.html'
    });
}());
