(function () {
  'use strict';
  const POINTS_PER_LEVEL = 500;
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this._ = $window._;
    }
    $onInit() {
      this.$firedux.waitForAuth(() => {
        const _ = this._;
        if (this.$firedux.auth.uid) {
          this.$firedux
            .ref('students')
            .child(this.$firedux.auth.uid)
            .child('scores')
            .on('value', snap => {
              const scores = snap.val() || [],
                  points = _(scores)
                    .chain()
                    .map(i => i)
                    .map('points')
                    .map(d => d > 0 ? d : 0)
                    .reduce((memo, i) => memo + i, 0)
                    .value(),
                  level = Math.floor(points / POINTS_PER_LEVEL) || 1;
              this.then({
                $data: level,
                $level: level,
                $points: points
              });
              this.$firedux.$apply();
            });
        }
      });
    }
    getLevelNumber(levelId) {
      let levelNumber = levelId.substring(5);
      return parseInt(levelNumber, 10);
    }
    isLevelComplete(levelId, level) {
      return levelId.indexOf('level') === 0 && level.isFinished;
    }
    $onDestroy() {
      this.$firedux.waitForAuth(() => {
        if (this.$firedux.auth.uid) {
          this.$firedux
            .ref('students')
            .child(this.$firedux.auth.uid)
            .child('progress')
            .off();
        }
      });
    }
  }
  angular
    .module('level', [])
    .component('itLevel', {
      controller: Controller,
      bindings: {
        then: '&'
      }
    });
}());
