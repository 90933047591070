(function () {
  'use strict';
  class Controller {
    constructor($window, $timeout) {
      this._ = $window._;
      this.$timeout = $timeout;
    }
    $onInit() {
      this.interval = parseInt(this.interval || 500, 10);
    }
    $processWords(words = {}) {
      const _ = this._;
      let wordAmount = this.wordAmount || 14,
          selectedWords = _(words).sample(wordAmount),
          testWords = _
            .chain()
            .union(
              _(selectedWords).sample(wordAmount / 2),
              _(words).sample(wordAmount / 2))
            .shuffle()
            .value();
      this.$start(selectedWords, testWords);
    }
    $start(words, testWords) {
      const $timeout = this.$timeout;
      let duration = this.duration || words.length * this.interval;
      this.words = words;
      this.cancelTimeout = $timeout(() => {
        this.testWords = testWords;
      }, duration);
    }
    $choose(chosen, word, length) {
      const _ = this._;
      if (
        _(chosen).chain().compact().size().value() < length / 2 &&
        !chosen[word]
      ) {
        chosen[word] = word;
      } else {
        chosen[word] = false;
      }
    }
    $verify(selectedWords) {
      const _ = this._;
      let words = (this.wordAmount || 14) / 2,
          correct,
          incorrect,
          missing;
      selectedWords = _(selectedWords).chain().toArray().compact().value();
      correct = _.intersection(selectedWords, this.words);
      incorrect = _.difference(selectedWords, this.words);
      missing = _.difference(_.intersection(this.testWords, this.words), selectedWords);
      this.then({
        $data: correct.length / words,
        $correct: correct,
        $incorrect: incorrect,
        $missing: missing
      });
    }
    $onDestroy() {
      this.$timeout.cancel(this.cancelTimeout);
    }
  }
  angular
    .module('excersise.wordRetention.excersise.component', [])
    .component('wordRetentionExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/wordRetention/excersise/component/component-excersise-wordRetention.comp.html',
      bindings: {
        interval: '<',
        wordAmount: '<',
        then: '&'
      }
    });
}());
