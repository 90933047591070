(function () {
  'use strict';
  angular
    .module('lesson', [
      'currentLesson',
      'lesson.splash',
      'lesson.lesson'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/lesson/:lessonId?stats&initial', '/lesson/:lessonId/splash?stats&initial');
      $stateProvider
        .state('lesson', {
          abstract: false,
          url: '/lesson/:lessonId?stats&initial',
          template: '<ui-view/>'
        });
    });
}());
