(function () {
  'use strict';
  class Controller {
    constructor($firedux) {
      this.$firedux = $firedux;
    }
    $next() {
      if (this.$currentItem !== 2 || this.registration.institutionType !== 'independent') {
        this.$currentItem = this.$currentItem + 1;
      } else {
        this.$currentItem = this.$currentItem + 2;
      }
    }
    $registerUser(registration) {
      let uid = this.$firedux.auth.uid;
      this.$isLoading = true;
      this.$firedux
        .ref('users')
        .child(uid)
        .child('registration')
        .set(registration)
        .then(() => Promise.all([
          this.$firedux
            .ref('users')
            .child(uid)
            .child('isRegistered')
            .set(true),
          this.$firedux
            .ref('users')
            .child(uid)
            .child('hasCategories')
            .set(true)
        ]))
        .then(() => {
          this.$isLoading = false;
          this.$firedux.$apply();
        });
    }
  }
  angular
    .module('profile', [])
    .component('profileEl', {
      controller: Controller,
      templateUrl: 'root/_components/userProfile/userProfile.comp.html'
    });
}());
