(function () {
  'use strict';
  class Controller {
    constructor($interval, $window) {
      this.$interval = $interval;
      this.$window = $window;
    }
    $onInit() {
      this.duration = parseInt(this.params.duration, 10) || 150;
      this.columns = parseInt(this.params.columns, 10) || 3;
      this.rows = parseInt(this.params.rows, 10) || 12;
      this.interpolation = this.params.reorderColumns ? 'reorder' : 'book';
      this.transform = this.params.showLetters ? 'letters' : 'numbers';
      this.$matrix = this.generateMatrix({
        rows: this.rows,
        columns: this.columns,
        interpolation: this.interpolation,
        transform: this.transform
      });
      this.initVertical();
    }
    $onDestroy() {
      this.$interval.cancel(this.interval);
    }
    generateMatrix({rows, columns, interpolation, transform}) {
      const _ = this.$window._,
          LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
          TRANSFORMS = {
            numbers: i => [i, i],
            letters: i => [i, getLetterMultiple(i)]
          },
          INTERPOLATIONS = {
            book: ({x, y}) => columns * (y - 1) + x,
            reorder: ({x, y}) => columns * (y - 1) + getAxis(x, columns)
          };
      return _.map(_.range(rows), y => _.map(_.range(columns), x => TRANSFORMS[transform](INTERPOLATIONS[interpolation]({x: x + 1, y: y + 1}))));
      function getAxis(x, total) {
        let indexes = [];
        _.each(_.range(total), i => indexes[i % 2 ? 'unshift' : 'push'](i + 1));
        return indexes[x - 1];
      }
      function getLetter(i) {
        return i > 0 ? LETTERS[i - 1] : '#';
      }
      function getLetterSingle(i) {
        return getLetter(i) || getLetterSingle(i - LETTERS.length);
      }
      function getLetterMultiple(i) {
        const repetitions = Math.ceil(i / LETTERS.length);
        return _.map(_.range(repetitions), () => getLetterSingle(i)).join('');
      }
    }
    initVertical() {
      const _ = this.$window._,
          func = () => {
            this.$currentI = this.$currentI ? this.$currentI + 1 : 1;
            if (this.$currentI > _.size(this.$matrix) * _.size(this.$matrix[0])) {
              this.then({$data: 1});
            }
          };
      func();
      this.interval = this.$interval(func, this.duration);
    }
  }
  angular
    .module('excersise.numbersWarmup.excersise.component', [])
    .component('numbersWarmupExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/numbersWarmup/excersise/component/component-excersise-numbersWarmup.comp.html',
      bindings: {
        params: '<',
        then: '&'
      }
    });
}());
