(function () {
  'use strict';
  class Controller {
    constructor($window, $http, $firedux) {
      this._ = $window._;
      this.$http = $http;
      this.$firedux = $firedux;
    }
    $average(items, query = 'Wpm', last = 2) {
      const _ = this._;
      let averages = _(items)
            .chain()
            .filter((scores, key) => key.indexOf(query) > -1)
            .map(scores => this.getAverage(scores, last))
            .value();
      return this.getAverage(averages);
    }
    getAverage(items, last = 99) {
      const _ = this._;
      let length = _(items).size();
      last = length < last ? length : last;
      return _(items)
        .chain()
        .toArray()
        .last(last)
        .reduce((memo, score) => memo + score)
        .value() / last;
    }
    $getPersonalData(studentId) {
      return this.$http.get('https://us-central1-intelligere-94e9b.cloudfunctions.net/globalStats?studentId=' + studentId)
        .then(({data}) => {
          this.$personalStats = data;
          this.$wpm = data.category.wpm.mean;
          this.$firedux.$apply();
        });
    }
  }
  angular
    .module('home.account.message', [])
    .component('homeAccountMessage', {
      controller: Controller,
      templateUrl: 'root/home/_components/account/message/message-account-home.comp.html'
    });
}());
