(function () {
  'use strict';

  angular
    .module('app', [
      'app.routes',
      'app.firedux',
      'app.themes',
      'app.analytics',
      'app.logic',
      'root'
    ]);
}());
