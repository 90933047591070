(function () {
  'use strict';
  class Controller {
  }
  angular
    .module('home.levels.level5', [
      'home.levels.level5.excersises'
    ])
    .component('homeLevelsLevel5', {
      controller: Controller,
      templateUrl: 'root/home/_components/levels/level5/level5-levels-home.comp.html',
      bindings: {
        isActive: '<'
      }
    });
}());
