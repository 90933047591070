(function () {
  'use strict';
  class Controller {
    constructor($window, $timeout, $firedux, $location) {
      this._ = $window._;
      this.math = $window.math;
      this.$firedux = $firedux;
      this.$timeout = $timeout;
      this.$level = parseInt($location.$$search.level, 10);
    }
    $parseInitialParams(params) {
      return this._.mapObject(params, item => item.default);
    }
    $divide(n, i) {
      return Math.ceil(n / i);
    }
    $get(obj, val) {
      let returnable = 0;
      if (obj && val) {
        returnable = this.math.eval(val, obj);
      }
      return returnable;
    }
    $receiveSavedParams(params) {
      this.$params = this._.mapObject(this.$params, (val, paramId) => this._.isUndefined((params || {})[paramId]) ? val : (params || {})[paramId]);
    }
    $saveParam(paramId, val) {
      return this.$firedux.ref(`students/${this.$uid}/params/${this.$lessonId}/${paramId}`).set(val);
    }
    $processParams(params, paramsDefinition) {
      return this._.mapObject(paramsDefinition, (definition, paramId) => this.math.eval(definition.transform || '$data', {$data: params[paramId] || definition.default}));
    }
    $bumpParams() {
      this._.each(this.$lesson.params, (param, paramId) => {
        if (param.autoincrease) {
          const newVal = this.$params[paramId] + (param.step || 1);
          this.$saveParam(paramId, newVal > param.max ? param.min : newVal);
        }
      });
    }
    $eval(exp, data, wpm, comprehension) {
      let result = exp ? this.math.eval(exp, {
        data: data || 1,
        wpm: wpm || 0,
        comprehension: comprehension || 0
      }) : null;
      return result;
    }
    $timeoutData() {
      this.$timeout(() => {
        delete this.$data;
      });
    }
    $getLessonId(lessonList, structure, progress) {
      const {_} = this;
      function sum(array) {
        return _(array).reduce((memo, item) => memo + item, 0);
      }
      let currentExcersise = Math.ceil(sum(progress) / 100 * lessonList.length);
      this.$currentExcersise = currentExcersise;
      lessonList = _(lessonList).pluck('excersiseId');
      this.$excersiseId = lessonList[currentExcersise];
      this.$progressPercentage = currentExcersise / lessonList.length * 100;
      return lessonList[currentExcersise];
    }
    $shuffle(input = []) {
      const {_} = this;
      let warmups = _(input).filter(excersise => (excersise || {}).excersiseId === 'warmup'),
          speeds = _(input).filter(excersise => (excersise || {}).excersiseId === 'speed'),
          excersises = _(input).filter(excersise => (excersise || {}).excersiseId !== 'warmup' && (excersise || {}).excersiseId !== 'speed'),
          spacingBetweenWarmups = Math.floor(input.length / warmups.length);
      excersises = _(excersises).shuffle();
      _(warmups).each((warmup, index) => excersises.splice(spacingBetweenWarmups * index, 0, warmup));
      _(speeds).each((speed) => excersises.push(speed));
      return excersises;
    }
    $getLessonSublevel(lessonId, scores, {sublevels, unit}) {
      if (unit === 'wpm') {
        unit = 'comprehension';
      }
      const groupedScores = this._.groupBy(this._.map(this.getLessonScores(lessonId, scores), item => Object.assign(item, {sublevel: (item || {}).sublevel || 0})), 'sublevel'),
          currentScores = this._.last(this._.toArray(groupedScores)),
          scoreAvg = this._.reduce(this._.map(this._.last(currentScores, 3), unit), (memo, item) => memo + item, 0) / 3,
          currentSublevel = (this._.last(currentScores) || {}).sublevel;
      let sublevel = currentSublevel;
      if (sublevels && sublevels[currentSublevel + 1] && meetsConditionsForNextSublevel(sublevels[currentSublevel + 1].requirement)) {
        sublevel++;
      }
      if (sublevels && sublevels[sublevel]) {
        this._.each(sublevels[sublevel].params, (data, key) => this.$saveParam(key, data));
      }
      return sublevel;
      function meetsConditionsForNextSublevel(requirement) {
        return requirement.attempts <= currentScores.length && (unit === 'milliseconds' ? requirement.score >= scoreAvg : requirement.score <= scoreAvg);
      }
    }
    getLessonScores(lessonId, scores) {
      return this._.filter(scores, ({
        lessonId: itemLessonId,
        milliseconds,
        percentage,
        wpm,
        number
      }) => lessonId === itemLessonId && this._.compact([
        milliseconds,
        percentage,
        number,
        wpm
      ]).length);
    }
  }
  angular
    .module('session', [
      'session.currentExcersise',
      'session.progress'
    ])
    .component('sessionEl', {
      controller: Controller,
      templateUrl: 'root/session/session.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('session', {
          abstract: false,
          url: '/session?level',
          template: '<session-el/>'
        });
    });
}());
