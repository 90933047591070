(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('actions')
    .component('authRegister', {
      controller: Controller,
      templateUrl: 'actions/auth/register/register-auth.action.html',
      bindings: {
        then: '&',
        catch: '&',
        login: '&'
      }
    });
}());
