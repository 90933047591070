(function () {
  'use strict';
  class Controller {
    constructor($rootScope, $window, $firedux, $state, $stateParams) {
      const _ = $window._;
      this.$rootScope = $rootScope;
      this.$firedux = $firedux;
      this.$window = $window;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.log = _((data, type) => {
        try {
          if (this.currentRef) {
            this.currentRef
              .child('logs')
              .child(type)
              .child(Date.now().toString())
              .set(data);
          }
        } catch (e) {
          return e;
        }
      }).throttle(1000);
      $window.firebase.auth().onAuthStateChanged(() => {
        this.disconnect();
        $firedux.ref().parent.child('.info/connected')
          .on('value', snap => {
            if (snap.val() === true) {
              this.connect();
            }
          });
        this.trackRouteViews();
        this.trackDispatcher();
      });
    }
    realtime(data, type) {
      if (this.currentRef) {
        this.currentRef
          .child('realtime')
          .child(type)
          .set(data);
      }
    }
    currentState() {
      return {
        name: this.$state.current.name.split('.').join('/'),
        params: angular.fromJson(angular.toJson(this.$stateParams))
      };
    }
    sendState() {
      this.realtime(this.currentState(), 'state');
      this.log(this.currentState(), 'state');
    }
    sendAction(action) {
      this.log(action, 'action');
    }
    sendError(action) {
      this.log(action, 'error');
    }
    trackRouteViews() {
      this.$rootScope.$on(
        '$stateChangeSuccess',
        () => {
          this.sendState();
        });
    }
    trackDispatcher() {
      this.$rootScope.$on(
        'fd:action',
        (event, data) => {
          this.sendAction(data);
        });
      this.$rootScope.$on(
        'fd:error',
        (event, data) => {
          this.sendError(data.toString());
        });
    }
    connect() {
      if (this.$firedux.auth.uid) {
        this.currentRef = this.$firedux
          .ref('analytics')
          .child('users')
          .child(this.$firedux.auth.uid);
        this.currentRef
          .child('realtime')
          .onDisconnect()
          .remove();
        this.currentRef
          .child('lastSeen')
          .onDisconnect()
          .set(this.$firedux.var('TIMESTAMP'));
        this.sendState();
      }
    }
    disconnect() {
      if (this.currentRef) {
        this
          .currentRef
          .child('realtime')
          .remove();
      }
    }
  }
  angular
      .module('app.analytics', [])
      .component('appAnalytics', {
        controller: Controller
      });
}());
