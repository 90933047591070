(function () {
  'use strict';
  class Controller {
    constructor($window, $timeout, $state) {
      this._ = $window._;
      this.$timeout = $timeout;
      this.$state = $state;
    }
    getWords() {
      return this.article.content.body.split(' ').length;
    }
    getMinTime() {
      let words = this.getWords(),
          minWpm = 2000,
          millPerMins = 60000;
      return millPerMins * words / minWpm;
    }
    $onInit() {
      this.initTimestamp = Date.now();
      this.timeout = this.$timeout(() => {
        this.$canContinue = true;
      }, this.getMinTime());
    }
    $onDestroy() {
      this.$timeout.cancel(this.timeout);
    }
    $then() {
      const words = this.getWords(),
          minutes = (Date.now() - this.initTimestamp) / 60000;
      this.then({
        $data: words / minutes
      });
    }
  }
  angular
    .module('excersise.doubleFixation.excersise.component', [])
    .component('doubleFixationExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/doubleFixation/excersise/component/component-excersise-doubleFixation.comp.html',
      bindings: {
        article: '<',
        then: '&'
      }
    });
}());
