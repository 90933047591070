(function () {
  'use strict';
  class Controller {
    constructor($window, $timeout) {
      this._ = $window._;
      this.$timeout = $timeout;
    }
    $onInit() {
      this.wordAmount = parseInt(this.wordAmount || 30, 10);
      this.timePerWord = parseInt(this.timePerWord || 250, 10);
      this.opacity = parseFloat(this.opacity || 0.85, 10);
      this.height = parseInt(this.height || 45, 10);
    }
    $processWords(words = {}) {
      let time;
      this.words = words;
      this.test = [];
      this.dictionary = [];
      for (let i = 0; i < this.wordAmount; i++) {
        this.addUnique(this.dictionary, words);
        this.addUnique(this.test, words);
      }
      // Make test half its size
      this.test = this._(this.test)
        .first(this.wordAmount / 2);
      // Merge test and dictionary
      this.test = this._.chain(this.test).union(this._(this.dictionary).chain().shuffle().first(this.wordAmount / 2).value()).shuffle().value();
      // Set minimum time to continue
      time = this.wordAmount * this.timePerWord;
      this.$timeout(() => {
        this.$canContinue = true;
      }, time);
    }
    addUnique(array, dictionary) {
      if (angular.isArray(array) && angular.isObject(dictionary)) {
        let word = this._(dictionary).sample();
        if (this._(array).indexOf(word) > 0 && word.length < 8) {
          this.addUnique(array, dictionary);
        } else {
          array.push(word);
        }
      }
    }
    $choose(chosen, word) {
      if (
        this._.chain(chosen).compact().size().value() < this.wordAmount / 2 &&
        !chosen[word]
      ) {
        chosen[word] = true;
      } else {
        chosen[word] = false;
      }
    }
    $verify(chosen, dictionary) {
      let total = this.wordAmount / 2,
          selected = [],
          percentage,
          correct,
          incorrect,
          missing;
      this._(chosen).each((val, key) => {
        if (val) {
          selected.push(key);
        }
      });
      correct = this._.intersection(selected, dictionary);
      incorrect = this._.difference(selected, dictionary);
      missing = this._.difference(this._.intersection(dictionary, this.test), selected);
      percentage = correct.length / total;
      return this.then({
        $data: percentage,
        $correct: correct,
        $incorrect: incorrect,
        $missing: missing
      });
    }
  }
  angular
    .module('excersise.truncated.excersise.component', [])
    .component('truncatedExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/truncated/excersise/component/component-excersise-truncated.route.html',
      bindings: {
        wordAmount: '<',
        timePerWord: '<',
        opacity: '<',
        height: '<',
        then: '&'
      }
    });
}());
