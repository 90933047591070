(function () {
  'use strict';
  angular
    .module('excersise.tripleColumn', [
      'excersise.tripleColumn.splash',
      'excersise.tripleColumn.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/tripleColumn', '/excersise/tripleColumn/splash');
      $stateProvider
        .state('excersise.tripleColumn', {
          abstract: false,
          url: '/tripleColumn',
          template: '<ui-view/>'
        });
    });
}());
