(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('session.progress', [])
    .component('sessionProgress', {
      controller: Controller,
      templateUrl: 'root/session/_components/progress/progress-session.comp.html',
      bindings: {
        progress: '<'
      }
    });
}());
