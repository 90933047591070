(function () {
  'use strict';
  angular
    .module('excersise.wordRetention', [
      'excersise.wordRetention.splash',
      'excersise.wordRetention.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/wordRetention', '/excersise/wordRetention/splash');
      $stateProvider
        .state('excersise.wordRetention', {
          abstract: false,
          url: '/wordRetention',
          template: '<ui-view/>'
        });
    });
}());
