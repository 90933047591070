(function () {
  'use strict';
  class Controller {
    constructor($window, $timeout) {
      this._ = $window._;
      this.$timeout = $timeout;
      this.currentNumber = 1;
    }
    $onInit() {
      const _ = this._;
      let numberAmount = parseInt(this.numberAmount || 28, 10) + 1,
          duration = parseInt(this.duration || 60000, 10);
      this.numbers = _.chain(1).range(numberAmount).shuffle().value();
      this.cancelTimeout = this.$timeout(() => {
        this.then({
          $data: this.currentNumber - 1
        });
      }, duration);
    }
    $verifyNumber(number, index) {
      if (this.currentNumber === number) {
        this.$timeout(() => {
          this.numbers[index] = this.currentNumber + this.numbers.length;
          this.currentNumber += 1;
        }, 400);
      }
    }
    $onDestroy() {
      this.$timeout.cancel(this.cancelTimeout);
    }
  }
  angular
    .module('excersise.findNumbers.excersise.component', [])
    .component('findNumbersExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/findNumbers/excersise/component/component-excersise-findNumbers.comp.html',
      bindings: {
        duration: '<',
        numberAmount: '<',
        then: '&'
      }
    });
}());
