(function () {
  'use strict';
  class Controller {
    constructor($interval) {
      this.$interval = $interval;
    }
    $onInit() {
      this.duration = parseInt(this.duration, 10) || 150;
      this.repetitions = parseInt(this.repetitions, 10) || 2;
      this.initVertical();
    }
    $onDestroy() {
      this.$interval.cancel(this.interval);
    }
    initVertical() {
      let level = 0,
          step = 0,
          odd = false,
          repetition = 0;
      const func = () => {
        this.x = 10 + 15 * level;
        this.y = step * 12.5 - 50;
        step++;
        if (step === 12) {
          step = 0;
          level++;
        }
        if (level === 6) {
          repetition++;
          level = 0;
        }
        if (repetition === this.repetitions) {
          this.$interval.cancel(this.interval);
          this.x = 50;
          this.y = 120;
          this.then();
        }
        odd = !odd;
      };
      func();
      this.interval = this.$interval(func, this.duration);
    }
  }
  angular
    .module('excersise.arrowsWarmup.excersise.component', [])
    .component('arrowsWarmupExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/arrowsWarmup/excersise/component/component-excersise-arrowsWarmup.comp.html',
      bindings: {
        duration: '<',
        repetitions: '<',
        then: '&'
      }
    });
}());
