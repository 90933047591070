(function () {
  'use strict';
  class Controller {
    constructor($timeout, $window) {
      const AVERAGE_LETTER_WIDTH = 9;
      let canvasWidth;
      this.$timeout = $timeout;
      this._ = $window._;
      canvasWidth = $window.innerWidth < 600 - 32 ? $window.innerWidth - 32 : 600 - 32;
      this.horizontalLetters = canvasWidth / AVERAGE_LETTER_WIDTH;
    }
    $onInit() {
      const _ = this._;
      let columns = [],
          diamondHeight = 16,
          reductionFactor = 1,
          remainingWords = [];
      this.words = _(this.phrases)
        .chain()
        .map(phrase => phrase.content.split(' '))
        .flatten()
        .value();
      remainingWords = [...this.words];
      while (remainingWords.length) {
        let newRow = '',
            newWords = [],
            index = columns.length,
            rowLength = (index % diamondHeight + 1) / diamondHeight * this.horizontalLetters * reductionFactor,
            isDescending = index % diamondHeight >= diamondHeight / 2;
        if (isDescending) {
          rowLength = (diamondHeight - (index % diamondHeight + 1)) / diamondHeight * this.horizontalLetters * reductionFactor;
        }
        while (newRow.length < rowLength) {
          let word = remainingWords.shift();
          newRow = `${newRow} ${word}`;
          newWords.push(word);
        }
        columns.push(newWords);
      }
      this.columns = columns;
      this.$setTimeout();
    }
    $setTimeout() {
      const MIN_WPM = 2000;
      let totalWords = this.words.length,
          minMinutes = totalWords / MIN_WPM,
          minTime = minMinutes * 60000;
      this.timeout = this.$timeout(() => {
        this.$canContinue = true;
      }, minTime);
      this.init = Date.now();
    }
    $then() {
      let totalWords = this.words.length,
          totalMilliseconds = Date.now() - this.init,
          totalMinutes = totalMilliseconds / 60000,
          wpm = totalWords / totalMinutes;
      this.then({
        $data: wpm
      });
    }
  }
  angular
    .module('excersise.diamondColumn.excersise.component', [])
    .component('diamondColumnExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/diamondColumn/excersise/component/component-excersise-diamondColumn.comp.html',
      bindings: {
        title: '<',
        phrases: '<',
        then: '&'
      }
    });
}());
