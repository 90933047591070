(function () {
  'use strict';
  angular
    .module('reducers', [
      'ng-webcat'
    ]);
  angular
    .module('actions', [
      'reducers'
    ]);
  angular
    .module('app.firedux', [
      'actions'
    ])
    .run(run);
  function run($firedux, $location, $window) {
    var time, scheduleLogout;
    const firebase = $window.firebase;
    $firedux.init({
      config: {
        apiKey: 'AIzaSyDyu31kKT-KQ-EjDflczcf03AxP_-g3gq8',
        authDomain: 'intelligere-94e9b.firebaseapp.com',
        databaseURL: 'https://intelligere-94e9b.firebaseio.com',
        storageBucket: 'intelligere-94e9b.appspot.com'
      },
      base: $location.protocol() === 'https' || true ? 'production' : 'staging'
    });
    // DOM Events
    $window.addEventListener('mousemove', resetTimer);
    $window.addEventListener('keypress', resetTimer);
    $window.addEventListener('beforeunload', () => {
      if (scheduleLogout && !$location.host.includes('localhost:3000')) {
        firebase.auth().signOut();
      }
    });
    function logout() {
      scheduleLogout = true;
    }
    function resetTimer() {
      $window.clearTimeout(time);
      scheduleLogout = false;
      time = $window.setTimeout(logout, 30000);
    }
  }
}());
