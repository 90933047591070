(function () {
  'use strict';
  class Controller {
    constructor($interval, $window) {
      this.$interval = $interval;
      this.$window = $window;
    }
    $onInit() {
      this.duration = parseInt(this.params.duration, 10) || 150;
      this.columns = parseInt(this.params.columns, 10) || 3;
      this.rotate = !!this.params.rotate;
      this.$matrix = this.generateMatrix(this.columns);
      this.initVertical();
    }
    $onDestroy() {
      this.$interval.cancel(this.interval);
    }
    generateMatrix(columns) {
      const _ = this.$window._,
          offset = columns * 2 + 1,
          offset2 = columns * 4 + 9;
      return [
        [..._.map(_.range(columns + 8), (d, i) => i * 2 + offset2)],
        [..._.map(_.range(columns + 4), (d, i) => i * 2 + offset)],
        [..._.map(_.range(columns), (d, i) => i * 2 + 1)],
        [..._.map(_.range(columns), (d, i) => i * 2 + 2)],
        [..._.map(_.range(columns + 4), (d, i) => i * 2 + offset + 1)],
        [..._.map(_.range(columns + 8), (d, i) => i * 2 + offset2 + 1)]
      ];
    }
    initVertical() {
      const func = () => {
        this.$currentI = this.$currentI ? this.$currentI + 1 : 1;
        if (this.$currentI > this.columns * 6 + 24) {
          this.then({$data: 1});
        }
      };
      func();
      this.interval = this.$interval(func, this.duration);
    }
  }
  angular
    .module('excersise.numbersWarmup4.excersise.component', [])
    .component('numbersWarmup4ExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/numbersWarmup4/excersise/component/component-excersise-numbersWarmup4.comp.html',
      bindings: {
        params: '<',
        then: '&'
      }
    });
}());
