(function () {
  'use strict';
  angular
    .module('excersise.findNumbers', [
      'excersise.findNumbers.splash',
      'excersise.findNumbers.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/findNumbers', '/excersise/findNumbers/splash');
      $stateProvider
        .state('excersise.findNumbers', {
          abstract: false,
          url: '/findNumbers',
          template: '<ui-view/>'
        });
    });
}());
