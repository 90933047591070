(function () {
  'use strict';
  angular
    .module('excersise.doubleFixation', [
      'excersise.doubleFixation.splash',
      'excersise.doubleFixation.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/doubleFixation', '/excersise/doubleFixation/splash');
      $stateProvider
        .state('excersise.doubleFixation', {
          abstract: false,
          url: '/doubleFixation',
          template: '<ui-view/>'
        });
    });
}());
