(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
      this.currentCycle = 0;
    }
    $onInit() {
      this.wordAmount = parseInt(this.wordAmount || 30, 10);
      this.repetitions = parseInt(this.repetitions || 10, 10);
    }
    $processWords(words = {}) {
      const _ = this._;
      let wordAmount = this.wordAmount;
      words = _(words).toArray();
      this.startTime = Date.now();
      this.dictionary = _(words)
        .chain()
        .toArray()
        .filter(word => word.length < 13)
        .sample(wordAmount)
        .value();
      this.selectedWords = _(this.dictionary).sample(this.repetitions);
      this.$tick();
    }
    $tick() {
      this.currentWord = this.selectedWords[this.currentCycle];
      this.currentCycle += 1;
    }
    $verifyWord(word) {
      let cycles = this.repetitions - 1,
          totalTime = Date.now() - this.startTime,
          returnable;
      if (word === this.currentWord) {
        if (this.currentCycle <= cycles) {
          this.$tick();
          returnable = true;
        } else {
          this.then({
            $data: totalTime / cycles
          });
        }
      }
      return returnable;
    }
  }
  angular
    .module('excersise.findWord.excersise.component', [])
    .component('findWordExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/findWord/excersise/component/component-excersise-findWord.route.html',
      bindings: {
        wordAmount: '<',
        repetitions: '<',
        then: '&'
      }
    });
}());
