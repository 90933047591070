(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this._ = $window._;
    }
    $onInit() {
      // If measurement and value are added as a global object
      if (angular.isObject(this.global)) {
        angular.forEach(this.global, (value, measurement) => {
          let hit = {},
              date = Date.now().toString();
          hit[date] = value;
          this.saveGlobal(measurement, hit);
        });
      // If measurement and value are added separately
      } else if (this.measurement && this.value) {
        let hit = {},
            date = Date.now().toString();
        hit[date] = this.value;
        this.saveGlobal(this.measurement, hit);
      }
      // If a level, measurement and a score are set
      if (this.level && this.score && this.measurement) {
        this.saveLevel(this.level, this.measurement, this.score);
      }
      // If there is a parameter watcher
      if (this.onParams) {
        this.$firedux.waitForAuth(() => {
          this.watchParams(this.$firedux.auth.uid);
        });
      }
      // If there is a score watcher
      if (this.onScore && this.level && this.measurement) {
        this.$firedux.waitForAuth(() => {
          this.watchScore(this.level, this.measurement, this.$firedux.auth.uid);
        });
      }
    }
    $onChanges() {
      // Sync params on every data change
      if (angular.isObject(this.params) && this.dataSynced) {
        this.saveParams(this.params);
      }
    }
    $onDestroy() {
      // Remove params watcher
      if (this.$firedux.auth.uid) {
        this.$firedux
          .ref('students')
          .child(this.$firedux.auth.uid)
          .child('params')
          .off();
      }
      // Remove score watcher
      if (this.onScore && this.level && this.measurement && this.$firedux.auth.uid) {
        this.$firedux
          .ref('students')
          .child(this.$firedux.auth.uid)
          .child('progress/level' + this.level)
          .child(this.measurement)
          .off();
      }
    }
    watchParams(uid) {
      this.watcher = uid ? this.$firedux
        .ref('students')
        .child(uid)
        .child('params')
        .on('value', snap => {
          this.dataSynced = true;
          this.onParams({
            $data: snap.val() || {}
          });
          this.$firedux.$apply();
        }) : false;
    }
    watchScore(level, measurement, uid) {
      this.watcher = uid ? this.$firedux
        .ref('students')
        .child(uid)
        .child('progress/level' + level)
        .child(measurement)
        .on('value', snap => {
          this.dataSynced = true;
          this.onScore({
            $data: snap.val() || 0
          });
          this.$firedux.$apply();
        }) : false;
    }
    saveLevel(level, measurement, score) {
      return this.$firedux.auth.uid ? this.$firedux
        .ref('students')
        .child(this.$firedux.auth.uid)
        .child('progress/level' + level)
        .child(measurement)
        .transaction(previous => {
          return (previous || 0) + score;
        }) : Promise.resolve();
    }
    saveGlobal(measurement, hit) {
      return this.$firedux.auth.uid ? this.$firedux
        .ref('students')
        .child(this.$firedux.auth.uid)
        .child('progress/global')
        .child(measurement)
        .update(hit) : Promise.resolve();
    }
    saveParams(params) {
      return this.$firedux.auth.uid ? this.$firedux
        .ref('students')
        .child(this.$firedux.auth.uid)
        .child('params')
        .update(params) : Promise.resolve();
    }
  }
  angular
    .module('hit', [])
    .component('itHit', {
      controller: Controller,
      templateUrl: 'root/_data/hit/hit.comp.html',
      bindings: {
        global: '<',
        measurement: '@',
        value: '<',
        params: '<',
        level: '<',
        score: '<',
        onParams: '&',
        onScore: '&'
      }
    });
}());
