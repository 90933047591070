(function () {
  'use strict';
  class Controller {
    constructor($timeout, $rootScope, $state) {
      this.$timeout = $timeout;
      this.$rootScope = $rootScope;
      this.$state = $state;
      this.INTERVAL = 15 * 60 * 1000;
    }
    $onInit() {
      this.startCount();
    }
    $onDestroy() {
      clearTimeout(this.timeout);
    }
    startCount() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = this.$timeout(() => {
        this.showDialog();
      }, this.INTERVAL);
    }
    showDialog() {
      this.watchState(() => {
        if (this.$state.current.name === 'home') {
          this.$showDialog = true;
          this.disabled = true;
          this.$timeout(() => {
            this.disabled = false;
          }, 60000);
        }
      });
    }
    closeDialog() {
      this.startCount();
      this.$showDialog = false;
    }
    watchState(callback) {
      this.unsubscribe = this.$rootScope.$on('$stateChangeSuccess', () => {
        callback();
        this.unsubscribe();
      });
      callback();
    }
  }
  angular
    .module('activePauses', [])
    .component('activePauses', {
      controller: Controller,
      templateUrl: 'root/_components/activePauses/activePauses.comp.html'
    });
}());
