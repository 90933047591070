(function () {
  'use strict';
  class Controller {
    constructor($state) {
      this.$state = $state;
    }
    $back() {
      this.$state.go('excersise.arrowsWarmup');
    }
  }
  angular
    .module('excersise.arrowsWarmup.excersise', [
      'excersise.arrowsWarmup.excersise.component'
    ])
    .component('excersiseArrowsWarmupExcersise', {
      controller: Controller,
      templateUrl: 'root/excersise/arrowsWarmup/excersise/excersise-arrowsWarmup-excersise.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('excersise.arrowsWarmup.excersise', {
          abstract: false,
          url: '/excersise',
          template: '<excersise-arrows-warmup-excersise/>'
        });
    });
}());
