(function () {
  'use strict';
  class Controller {
    constructor($interval, $window) {
      this.$interval = $interval;
      this.$window = $window;
    }
    $onInit() {
      this.duration = parseInt(this.params.duration, 10) || 150;
      this.columns = parseInt(this.params.columns, 10) || 3;
      this.rows = parseInt(this.params.rows, 10) || 12;
      this.$matrix = this.generateMatrix({rows: this.rows, columns: this.columns});
      this.initVertical();
    }
    $onDestroy() {
      this.$interval.cancel(this.interval);
    }
    generateMatrix({rows, columns}) {
      const _ = this.$window._,
          offset = rows * 2 + 1;
      return [
        [..._.map(_.range(columns), (d, i) => i * 2 + offset)],
        ..._.map(_.range(rows), (d, i) => [i * 2 + 1, i * 2 + 2]),
        [..._.map(_.range(columns), (d, i) => i * 2 + offset + 1)].reverse()
      ];
    }
    initVertical() {
      const func = () => {
        this.$currentI = this.$currentI ? this.$currentI + 1 : 1;
        if (this.$currentI > this.rows * 2 + this.columns * 2) {
          this.then({$data: 1});
        }
      };
      func();
      this.interval = this.$interval(func, this.duration);
    }
  }
  angular
    .module('excersise.numbersWarmup3.excersise.component', [])
    .component('numbersWarmup3ExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/numbersWarmup3/excersise/component/component-excersise-numbersWarmup3.comp.html',
      bindings: {
        params: '<',
        then: '&'
      }
    });
}());
