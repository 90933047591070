(function () {
  'use strict';
  class Controller {
    constructor($timeout, $window, $location, $firedux, $http) {
      this._ = $window._;
      this.moment = $window.moment;
      this.math = $window.math;
      this.$timeout = $timeout;
      this.$firedux = $firedux;
      this.$window = $window;
      this.$http = $http;
      this.$rootUrl = $location.$$absUrl.replace('splash', '');
    }
    $sortBy(list, text) {
      return this._.chain(list).map((i, id) => Object.assign(i, {id})).sortBy(i => i[text]).value();
    }
    $getPersonalData(studentId, lessonId, scores) {
      return Promise.all([
        this.$http.get('https://us-central1-intelligere-94e9b.cloudfunctions.net/globalStats?studentId=' + studentId)
          .then(({data: {lessons: {[lessonId]: lesson}}}) => {
            this.$personalStats = lesson;
          }),
        this.$http.get('https://us-central1-intelligere-94e9b.cloudfunctions.net/globalStats')
          .then(({data: {lessons: {[lessonId]: lesson}, users}}) => {
            this.$globalStats = Object.assign(lesson, {users});

            this.chartGlobalWpm = this.$getGlobalChart('wpm', lesson, this._.size(scores));
            this.chartGlobalComprehension = this.$getGlobalChart('comprehension', lesson, this._.size(scores));
            this.chartGlobalNumber = this.$getGlobalChart('number', lesson, this._.size(scores));
            this.chartGlobalPercentage = this.$getGlobalChart('percentage', lesson, this._.size(scores));
            this.chartGlobalMilliseconds = this.$getGlobalChart('milliseconds', lesson, this._.size(scores));
          })
      ]);
    }
    $goBack() {
      this.$window.history.back();
    }
    $getChartOptions(measurementId) {
      let unit;
      switch (measurementId) {
        case 'milliseconds':
          unit = 's';
          break;
        case 'percentage':
          unit = '%';
          break;
        case 'wpm':
          unit = ' ppm';
          break;
        case 'comprehension':
          unit = '%';
          break;
        default:
          unit = '';
          break;
      }
      return {
        scales: {
          yAxes: [{
            beginAtZero: false,
            ticks: {
              callback(value) {
                switch (measurementId) {
                  case 'milliseconds':
                    value = value.toFixed(1);
                    break;
                  case 'percentage':
                    value = value.toFixed(0);
                    break;
                  case 'comprehension':
                    value = value.toFixed(0);
                    break;
                  default:
                    value = value.toFixed(2);
                    break;
                }
                return `${Math.abs(value)}${unit}`;
              }
            }
          }],
          xAxes: [{
            type: 'linear',
            ticks: {
              callback(i) {
                return i === Math.floor(i) ? `#${i + 1}` : '';
              }
            }
          }]
        },
        elements: {
          line: {
            tension: 0.5
          }
        }
      };
    }
    $getTotalTime(progress) {
      const millis = this._(progress).chain()
        .map('duration')
        .reduce((memo, item) => memo + item, 0)
        .value(),
          mins = Math.floor(millis / (1000 * 60)),
          secs = Math.floor(millis / 1000) - mins * 60;
      return [mins, secs];
    }
    $time(time) {
      const mins = Math.floor(time / (1000 * 60)),
          secs = Math.floor(time / 1000) - mins * 60;
      return [mins, secs];
    }
    $getGlobalChart(unit, {lowAvg, topAvg, unit: scoreUnit, wpm}, size) {
      let returnable;
      if (wpm && unit === 'wpm') {
        returnable = getCoordinates(wpm.wpm.lowAvg, wpm.wpm.topAvg);
      } else if (wpm && unit === 'comprehension') {
        returnable = getCoordinates(wpm.comprehension.lowAvg * 100, wpm.comprehension.topAvg * 100);
      } else if (scoreUnit === unit && unit === 'percentage') {
        returnable = getCoordinates(lowAvg * 100, topAvg * 100);
      } else if (scoreUnit === unit && unit === 'milliseconds') {
        returnable = getCoordinates(-lowAvg / 1000, -topAvg / 1000);
      } else if (scoreUnit === unit) {
        returnable = getCoordinates(lowAvg, topAvg);
      } else {
        returnable = [];
      }
      return returnable;
      function getCoordinates(low, top) {
        return [{x: 0, y: `${low}`}, {x: size - 1, y: `${top}`}];
      }
    }
    $getLessonProgress(lessonId, scores, {optimalScore}) {
      return this._(scores)
        .chain()
        .filter(({
          lessonId: itemLessonId,
          milliseconds,
          percentage,
          wpm,
          number
        }) => lessonId === itemLessonId && this._.compact([
          milliseconds,
          percentage,
          number,
          wpm
        ]).length || lessonId === 'warmup' || lessonId === 'arrowsWarmup')
        .map((score) => {
          const {milliseconds, percentage, wpm, comprehension, number} = score,
              cwpm = wpm && wpm * comprehension,
              value = milliseconds || percentage || number || cwpm || 0,
              effectiveness = milliseconds ? Math.floor(100 * optimalScore / value) : Math.floor(100 * value / optimalScore),
              color = this.getColor(effectiveness);
          return Object.assign(score, {
            effectiveness,
            color
          });
        })
        .value()
        .reverse();
    }
    $getGlobalProgress() {
    }
    $getLessonSublevel(lessonId, scores, {sublevels, unit}) {
      if (unit === 'wpm') {
        unit = 'comprehension';
      }
      console.log(lessonId, scores, {sublevels, unit});
      const groupedScores = this._.groupBy(this._.map(this.getLessonScores(lessonId, scores), item => Object.assign(item, {sublevel: (item || {}).sublevel || 0})), 'sublevel'),
          currentScores = this._.last(this._.toArray(groupedScores)) || [],
          scoreAvg = this._.reduce(this._.map(this._.last(currentScores, 3), unit), (memo, item) => memo + item, 0) / 3,
          currentSublevel = (this._.last(currentScores) || {}).sublevel || 0;
      let sublevel = currentSublevel;
      if (sublevels && sublevels[currentSublevel + 1] && meetsConditionsForNextSublevel(sublevels[currentSublevel + 1].requirement)) {
        sublevel++;
      }
      if (sublevels && sublevels[sublevel]) {
        this._.each(sublevels[sublevel].params, (data, key) => this.$saveParam(key, data));
      }
      return sublevel;
      function meetsConditionsForNextSublevel(requirement) {
        return requirement.attempts <= currentScores.length && (unit === 'milliseconds' ? requirement.score >= scoreAvg : requirement.score <= scoreAvg);
      }
    }
    getLessonScores(lessonId, scores) {
      return this._.filter(scores, ({
        lessonId: itemLessonId,
        milliseconds,
        percentage,
        wpm,
        number
      }) => lessonId === itemLessonId && this._.compact([
        milliseconds,
        percentage,
        number,
        wpm
      ]).length);
    }
    getColor(effectiveness) {
      let color = '#f44336';
      if (effectiveness > 100) {
        color = '#00BCD4';
      } else if (effectiveness > 75) {
        color = '#8BC34A';
      } else if (effectiveness > 60) {
        color = '#CDDC39';
      } else if (effectiveness > 30) {
        color = '#FF9800';
      } else if (effectiveness > 10) {
        color = '#FF5722';
      }
      return color;
    }
    getLabel(effectiveness) {
      let label = 'Nivel muy bajo';
      if (effectiveness > 100) {
        label = 'Nivel excelente';
      } else if (effectiveness > 75) {
        label = 'Nivel bueno';
      } else if (effectiveness > 60) {
        label = 'Nivel aceptable';
      } else if (effectiveness > 30) {
        label = 'Nivel medio';
      } else if (effectiveness > 10) {
        label = 'Nivel bajo';
      }
      return label;
    }
    $getChartData(measurementId, scores, {unit = ''}, sortByUnit) {
      if (unit.indexOf(measurementId) > -1 || measurementId === 'duration' || measurementId === 'comprehension' && unit === 'wpm') {
        const data = this._(scores).chain()
          .sortBy(sortByUnit ? `${measurementId}` : 'timestamp')
          .map(item => item[measurementId])
          .map(item => {
            let returnable;
            switch (measurementId) {
              case 'milliseconds':
                returnable = (-item / 1000).toFixed(1);
                break;
              case 'duration':
                returnable = (item / 1000).toFixed(1);
                break;
              case 'percentage':
                returnable = (item * 100).toFixed(0);
                break;
              case 'comprehension':
                returnable = (item * 100).toFixed(0);
                break;
              default:
                returnable = item.toFixed(2);
                break;
            }
            return returnable;
          })
          .value();
        return this._(measurementId === 'milliseconds' && sortByUnit ? data.reverse() : data)
          .map((y, x) => ({x, y}));
      }
    }
    $getMultipleChartData(measurementId, scores, lesson, sortByUnit) {
      const {sublevels = [1]} = lesson,
          {_} = this;
      return _.map(sublevels, (item, index) => this.$getChartData(measurementId, _.filter(scores, ({sublevel = 0}) => sublevel === index), lesson, sortByUnit));
    }
    $getChartDurationDays(scores) {
      const durations = this._(scores).chain()
        .sortBy('timestamp')
        .filter('duration')
        .groupBy(item => this.moment(item.timestamp).startOf('day').valueOf())
        .mapObject(items => Math.floor(this._(items).chain().map('duration').reduce((m, i) => m + i, 0).value() / 1000))
        .value();
      return {
        labels: this._(durations).chain().keys().map(i => this.moment(parseInt(i, 10)).format('ll')).value(),
        data: this._.toArray(durations)
      };
    }
    $getImprovement(measurementId, scores, {unit = ''}) {
      const measurements = unit.indexOf(measurementId) > -1 || measurementId === 'duration' || measurementId === 'comprehension' && unit === 'wpm' ?
        this._(scores).chain()
          .sortBy('timestamp')
          .map(item => item[measurementId])
          .map(item => {
            let returnable;
            switch (measurementId) {
              case 'milliseconds':
                returnable = -item / 1000;
                break;
              case 'duration':
                returnable = item / 1000;
                break;
              case 'percentage':
                returnable = item * 100;
                break;
              default:
                returnable = item;
                break;
            }
            return returnable;
          })
          .value() : [],
          totalMeasurements = this._.size(measurements),
          samples = totalMeasurements > 6 ? 3 : Math.ceil(totalMeasurements / 2),
          maxValues = this._(measurements).chain().filter(i => i).sortBy(i => i).first(samples).value(),
          minValues = this._(measurements).chain().filter(i => i).sortBy(i => -i).first(samples).value(),
          maxAvg = this._(maxValues).reduce((memo, i) => memo + i, 0) / maxValues.length,
          minAvg = this._(minValues).reduce((memo, i) => memo + i, 0) / minValues.length;
      return totalMeasurements ? `${Math.abs((1 - maxAvg / minAvg) * 100).toFixed(0)}%` : '';
    }
    $getAverage(measurementId, scores, {unit = ''}) {
      const measurements = unit.indexOf(measurementId) > -1 || measurementId === 'duration' || measurementId === 'comprehension' && unit === 'wpm' ?
        this._(scores).chain()
          .sortBy('timestamp')
          .map(item => item[measurementId])
          .map(item => {
            let returnable;
            switch (measurementId) {
              case 'milliseconds':
                returnable = item / 1000;
                break;
              case 'duration':
                returnable = item / 1000;
                break;
              case 'percentage':
                returnable = item * 100;
                break;
              case 'comprehension':
                returnable = item * 100;
                break;
              default:
                returnable = item;
                break;
            }
            return returnable;
          })
          .value() : [],
          realMeasurements = this._(measurements).filter(i => i),
          average = this._(realMeasurements).reduce((memo, i) => memo + i, 0) / realMeasurements.length;
      return realMeasurements.length ? `${measurementId === 'milliseconds' ? average.toFixed(2) : average.toFixed(1)}` : '';
    }
    $getEffectiveness(measurementId, scores, {unit = '', optimalScore}) {
      const measurements = unit.indexOf(measurementId) > -1 || measurementId === 'duration' || measurementId === 'comprehension' && unit === 'wpm' ?
        this._(scores).chain()
          .sortBy('timestamp')
          .map(item => unit === 'wpm' ? item.wpm * item.comprehension : item[measurementId])
          .sortBy()
          .last(5)
          .value() : [],
          realMeasurements = this._(measurements).filter(i => i),
          average = this._(realMeasurements).reduce((memo, i) => memo + i, 0) / realMeasurements.length;
      return measurementId === 'milliseconds' ? Math.floor(100 * optimalScore / average) : Math.floor(100 * average / optimalScore);
    }
    $getUnitName(unit) {
      let returnable;
      switch (unit) {
        case 'wpm':
          returnable = 'Palabras por minuto';
          break;
        case 'comprehension':
          returnable = '% de comprensión';
          break;
        case 'percentage':
          returnable = 'Porcentaje';
          break;
        case 'number':
          returnable = 'Número alcanzado';
          break;
        case 'milliseconds':
          returnable = 'Segundos';
          break;
        default:
          returnable = '';
          break;
      }
      return returnable;
    }
    $parseInitialParams(params) {
      return this._.mapObject(params, item => item.default);
    }
    $paramsToQuery(params, definitions) {
      let processedParams = this._(params)
        .chain()
        .map((param, paramId) => `${paramId}=${this.math.eval(definitions[paramId].transform || '$data', {$data: param}) || ''}`)
        .value();
      processedParams.push(`sublevel=${this.$sublevel}`);
      return processedParams.join('&');
    }
    $receiveSavedParams(params) {
      this.$params = this._.mapObject(this.$params, (val, paramId) => this._.isUndefined((params || {})[paramId]) ? val : (params || {})[paramId]);
    }
    $saveParam(paramId, val) {
      return this.$firedux.ref(`students/${this.$uid}/params/${this.lessonId}/${paramId}`).set(val);
    }
  }
  angular
    .module('lesson.splash', [])
    .component('lessonSplash', {
      controller: Controller,
      templateUrl: 'root/lesson/splash/splash-lesson.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('lesson.splash', {
          abstract: false,
          url: '/splash?stats&initial',
          template: '<lesson-splash/>'
        });
    });
}());
