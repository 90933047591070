(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('excersise.truncated.excersise', [
      'excersise.truncated.excersise.component'
    ])
    .component('excersiseTruncatedExcersise', {
      controller: Controller,
      templateUrl: 'root/excersise/truncated/excersise/excersise-truncated-excersise.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('excersise.truncated.excersise', {
          abstract: false,
          url: '/excersise',
          template: '<excersise-truncated-excersise/>'
        });
    });
}());
