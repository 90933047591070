(function () {
  'use strict';
  function config($mdThemingProvider, ChartJsProvider) {
    let primaryMap = $mdThemingProvider
        .extendPalette('red', {
          400: '#7E3930',
          500: '#7E3930',
          600: '#7E3930',
          contrastDefaultColor: 'light'
        }),
        accentMap = $mdThemingProvider.extendPalette('cyan', {
          500: '#843B62',
          contrastDefaultColor: 'light'
        }),
        secondAccentMap = $mdThemingProvider.extendPalette('orange', {
          500: '#FF7700',
          contrastDefaultColor: 'dark'
        }),
        blackMap = $mdThemingProvider.extendPalette('grey', {
          800: '#00526A',
          900: '#00526A',
          contrastDefaultColor: 'dark'
        }),
        blueMap = $mdThemingProvider.extendPalette('grey', {
          500: '#1976D2',
          contrastDefaultColor: 'dark'
        });

    $mdThemingProvider.definePalette('primaryPalette', primaryMap);
    $mdThemingProvider.definePalette('secondaryPalette', secondAccentMap);
    $mdThemingProvider.definePalette('accentPalette', accentMap);
    $mdThemingProvider.definePalette('blackPalette', blackMap);
    $mdThemingProvider.definePalette('bluePalette', blueMap);

    $mdThemingProvider.theme('default')
      .primaryPalette('primaryPalette', {
        default: '500',
        'hue-1': '600',
        'hue-2': '400'
      })
      .accentPalette('accentPalette', {
        default: '500'
      });
    $mdThemingProvider.theme('secondary')
      .primaryPalette('secondaryPalette', {
        default: '500'
      })
      .accentPalette('secondaryPalette', {
        default: '500'
      });
    $mdThemingProvider.theme('black')
      .primaryPalette('blackPalette', {
        default: '900',
        'hue-1': '800',
        'hue-2': '50'
      })
      .accentPalette('bluePalette', {
        default: '500'
      });
    $mdThemingProvider.theme('comparison')
      .primaryPalette('purple', {
        default: '900',
        'hue-1': '800',
        'hue-2': '50'
      })
      .accentPalette('bluePalette', {
        default: '500'
      });
    ChartJsProvider.setOptions({
      tooltips: {
        enabled: false
      },
      colors: [
        '#1976D2',
        '#673AB7'
      ]
    });
  }
  angular
    .module('app.themes', ['chart.js'])
    .config(config);
}());
