(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('home.account.toolbar', [])
    .component('homeAccountToolbar', {
      controller: Controller,
      templateUrl: 'root/home/_components/account/toolbar/toolbar-account-home.comp.html',
      bindings: {
        then: '&'
      }
    });
}());
