(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $rand(max) {
      return Math.floor(Math.random() * max);
    }
    $size(obj) {
      return this._(obj).size();
    }
  }
  angular
    .module('excersise.wordInParagraph.excersise', [
      'excersise.wordInParagraph.excersise.component'
    ])
    .component('excersiseWordInParagraphExcersise', {
      controller: Controller,
      templateUrl: 'root/excersise/wordInParagraph/excersise/excersise-wordInParagraph-excersise.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('excersise.wordInParagraph.excersise', {
          abstract: false,
          url: '/excersise',
          template: '<excersise-word-in-paragraph-excersise/>'
        });
    });
}());
