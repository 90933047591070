(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
      this.chartOptions = {
        scales: {
          yAxes: [{
            beginAtZero: false,
            ticks: {
              callback(value) {
                return `${value}`;
              }
            }
          }],
          xAxes: [{
            type: 'time',
            ticks: {
              callback() {
                return '';
              }
            }
          }]
        },
        elements: {
          line: {
            tension: 0
          }
        }
      };
    }
    parseData(data, isPercentage) {
      const _ = this._;
      return _(data)
        .map((item) => {
          return {
            x: parseInt(item.key, 10),
            y: item.value * (isPercentage ? 100 : 1)
          };
        });
    }
  }
  angular
    .module('excersise.tripleColumn.splash', [])
    .component('excersiseTripleColumnSplash', {
      controller: Controller,
      templateUrl: 'root/excersise/tripleColumn/splash/splash-tripleColumn-excersise.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('excersise.tripleColumn.splash', {
          abstract: false,
          url: '/splash',
          template: '<excersise-triple-column-splash/>'
        });
    });
}());
