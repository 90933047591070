(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this.$window = $window;
    }
    $logout() {
      this.$firedux.logout();
    }
    $openWizard(uid, preventPlop) {
      this.$window.setTimeout(() => {
        if (!preventPlop) {
          this.$window.introJs().start();
        }
      }, 2000);
      this.$firedux.ref('users').child(uid).child('homeWizard').set(true);
    }
  }
  angular
    .module('home', [
      'home.account',
      'home.title',
      'home.levels'
    ])
    .component('homeEl', {
      controller: Controller,
      templateUrl: 'root/home/home.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('home', {
          abstract: false,
          url: '/home',
          template: '<home-el/>'
        });
    });
}());
