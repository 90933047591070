(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('session.currentExcersise', [])
    .component('sessionCurrentExcersise', {
      controller: Controller,
      templateUrl: 'root/session/_components/currentExcersise/currentExcersise-session.comp.html',
      bindings: {
        excersiseId: '@',
        excersise: '<',
        params: '<',
        level: '<',
        then: '&'
      }
    });
}());
