(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('excersise.findNumbers.excersise', [
      'excersise.findNumbers.excersise.component'
    ])
    .component('excersiseFindNumbersExcersise', {
      controller: Controller,
      templateUrl: 'root/excersise/findNumbers/excersise/excersise-findNumbers-excersise.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('excersise.findNumbers.excersise', {
          abstract: false,
          url: '/excersise',
          template: '<excersise-find-numbers-excersise/>'
        });
    });
}());
