(function () {
  'use strict';
  class Controller {
    constructor($firedux) {
      this.$firedux = $firedux;
    }
    $logout() {
      this.$firedux.logout();
    }
  }
  angular
    .module('account', [
      'account.progress',
      'account.subscription'
    ])
    .component('accountEl', {
      controller: Controller,
      templateUrl: 'root/account/account.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('account', {
          abstract: false,
          url: '/account',
          template: '<account-el/>'
        });
    });
}());
