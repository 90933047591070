(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this.$window = $window;
    }
    $logout() {
      this.$firedux.logout();
    }
    back() {
      this.$window.history.back();
    }
  }
  angular
    .module('group', [
      'group.progress'
    ])
    .component('groupEl', {
      controller: Controller,
      templateUrl: 'root/group/group.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('group', {
          abstract: false,
          url: '/group?institutionId&groupId&userId',
          template: '<group-el/>'
        });
    });
}());
