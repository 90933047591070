(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window, $state) {
      this.$firedux = $firedux;
      this._ = $window._;
      this.$state = $state;
    }
    $onChanges() {
      if (this.sessionRef && this.finishSession) {
        this.sessionRef.child('finishedAt').ref.set({
          '.sv': 'timestamp'
        }).then(() => this.$firedux.$apply()).then(() => this.$state.go('home'));
      }
    }
    $onInit() {
      this.$firedux.waitForAuth(() => {
        const _ = this._;
        // Make ref to query unfinished sessions
        let ref = this.$firedux
          .ref('students')
          .child(this.$firedux.auth.uid)
          .child('sessions')
          .orderByChild('finishedAt')
          .equalTo(null);
        ref
          .once('value')
          .then(snap => {
            const TWO_HOURS = 1000 * 60 * 60 * 2,
                sessions = snap.val(),
                currentSession = _.last(_.toArray(sessions)),
                isOldSession = (currentSession || {}).createdAt < Date.now() - TWO_HOURS || !(currentSession || {}).createdAt;
            let returnable, key;
            // If no sessions or session is too old, make session
            if (!currentSession || isOldSession) {
              returnable = this.levelId ? this.$firedux
                .ref('students')
                .child(this.$firedux.auth.uid)
                .child('sessions')
                .push({
                  createdAt: {
                    '.sv': 'timestamp'
                  },
                  levelId: this.levelId || null
                }) : null;
              key = returnable.key;
              returnable = returnable.then(() => key);
            // If session exists, use session
            } else if (!returnable) {
              snap.forEach(childSnap => {
                returnable = childSnap.key;
              });
            }
            return returnable;
          }).then((key) => {
            this.sessionRef = this.$firedux
              .ref('students')
              .child(this.$firedux.auth.uid)
              .child('sessions')
              .child(key);
            // Add score if there is progress info
            if (this.score) {
              this.sessionRef.child('progress').child(this.progress.toString()).set(this.score);
            }
            this.sessionRef.on('value', snap => {
              const session = snap.val() || {};
              if (!session.finishedAt) {
                this.then({
                  $data: session.progress || []
                });
                this.$firedux.$apply();
              }
            });
          });
      });
    }
    $onDestroy() {
      this.$firedux.waitForAuth(() => {
        this.$firedux
          .ref('students')
          .child(this.$firedux.auth.uid)
          .child('sessions')
          .orderByChild('finishedAt')
          .equalTo(null)
          .off();
      });
    }
  }
  angular
    .module('sessions', [])
    .component('itSessions', {
      controller: Controller,
      bindings: {
        cancel: '@',
        levelId: '@',
        finishSession: '<',
        score: '<',
        progress: '<',
        then: '&'
      }
    });
}());
