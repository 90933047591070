(function () {
  'use strict';
  class Controller {
    constructor($window, $interval, $timeout) {
      this._ = $window._;
      this.$interval = $interval;
      this.$timeout = $timeout;
    }
    $onInit() {
      this.interval = parseInt(this.interval || 750, 10);
      this.wordAmount = parseInt(this.wordAmount || 14, 10);
      this.beginningTime = parseInt(this.beginningTime || 8000, 10);
    }
    $processWords(words = {}) {
      const _ = this._;
      let wordAmount = this.wordAmount,
          selectedWords = _(words).sample(wordAmount),
          removedWords = _(selectedWords).sample(Math.ceil(wordAmount / 2)),
          randomWords = _(words)
            .chain()
            .toArray()
            .difference(selectedWords)
            .sample(Math.ceil(wordAmount / 2))
            .value(),
          testWords = _(randomWords)
            .chain()
            .union(removedWords)
            .shuffle()
            .value();
      this.$start(selectedWords, removedWords, testWords);
    }
    $start(selectedWords, removedWords, testWords) {
      let interval = this.interval,
          repetitions = removedWords.length,
          beginningTime = this.beginningTime;
      this.removedWords = removedWords;
      this.words = selectedWords;
      this.blockedWords = {};
      this.$progress = 1;
      this.$interval((iteration) => {
        this.$progress = 5 * iteration;
        this.$progress = this.$progress && this.$progress === 100 ? null : this.$progress;
      }, beginningTime / 20, 20);
      this.$timeout(() => {
        this.cancelTimeout = this.
          $interval((iteration) => {
            let currentWord = removedWords[iteration];
            this.blockedWords[currentWord] = true;
          }, interval, repetitions)
          .then(() => {
            this.testWords = testWords;
          });
      }, beginningTime);
    }
    $choose(chosen, word, length) {
      const _ = this._;
      if (
        _(chosen).chain().compact().size().value() < length / 2 &&
        !chosen[word]
      ) {
        chosen[word] = word;
      } else {
        chosen[word] = false;
      }
    }
    $verify(selectedWords) {
      const _ = this._;
      let words = this.wordAmount / 2,
          correct,
          incorrect,
          missing;
      selectedWords = _(selectedWords).toArray();
      correct = _.intersection(selectedWords, this.words);
      incorrect = _.compact(_.difference(selectedWords, this.words));
      missing = _.difference(this.removedWords, selectedWords);
      this.then({
        $data: correct.length / words,
        $correct: correct,
        $incorrect: incorrect,
        $missing: missing
      });
    }
  }
  angular
    .module('excersise.disappearingWords.excersise.component', [])
    .component('disappearingWordsExcersiseComponent', {
      controller: Controller,
      templateUrl: 'root/excersise/disappearingWords/excersise/component/component-excersise-disappearingWords.comp.html',
      bindings: {
        interval: '<',
        wordAmount: '<',
        beginningTime: '<',
        then: '&'
      }
    });
}());
