(function () {
  'use strict';
  angular
    .module('excersise.recognize', [
      'excersise.recognize.splash',
      'excersise.recognize.excersise'
    ])
    .config(function ($stateProvider, $urlRouterProvider) {
      $urlRouterProvider.when('/excersise/recognize', '/excersise/recognize/splash');
      $stateProvider
        .state('excersise.recognize', {
          abstract: false,
          url: '/recognize',
          template: '<ui-view/>'
        });
    });
}());
